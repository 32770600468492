import React from 'react';
import SEO from 'components/seo';
import Layout from 'components/layout';
import Banner from 'sections/banner';
import AboutUs from 'sections/aboutus';
import Menu from '../sections/menu';
import Staff from '../sections/staff';
import Access from '../sections/access';
import Detail from '../sections/detail';

export default function IndexPage() {
  return (
    <Layout>
      <SEO title="MarMot! Beauty" image="https://marmot-beauty-prod.firebaseapp.com/ogImage.png"/>
      <Banner />
      <AboutUs />
      <Menu />
      <Detail />
      <Staff />
      <Access />
    </Layout>
  );
}
