/** @jsx jsx */
import { jsx, Box, Container, Flex, Text, Button, Divider, Heading } from 'theme-ui';
import SectionHeading from '../components/section-heading'
import { useState } from 'react';
import { rgba } from 'polished';
import { StaticImage } from "gatsby-plugin-image"

import hihu01 from 'assets/images/hihu01.jpg';
import hihu02 from 'assets/images/hihu02.jpg';
import hihu03 from 'assets/images/hihu03.jpg';
import hihu04 from 'assets/images/hihu04.jpg';

import nextEMS01 from 'assets/images/nextEMS01.jpg';
import nextEMS02 from 'assets/images/nextEMS02.jpg';
import nextEMS03 from 'assets/images/nextEMS03.jpg';
import nextEMS04 from 'assets/images/nextEMS04.jpg';

import laserHair01 from 'assets/images/laserHair01.jpg';
import laserHair02 from 'assets/images/laserHair02.jpg';
import laserHair03 from 'assets/images/laserHair03.jpg';
import laserHair04 from 'assets/images/laserHair04.jpg';

import waxHair01 from 'assets/images/waxHair01.jpg';
import waxHair02 from 'assets/images/waxHair02.jpg';
import waxHair03 from 'assets/images/waxHair03.jpg';
import waxHair04 from 'assets/images/waxHair04.jpg';

import SimpleSlider from '../components/carousel';
import Campaigns from 'components/campaigns';

const Detail = () => {

const menuDetailList = {
    hihu: {
        id: 1,
        title: 'ハイフ',
        cards: [
            {
                id: 1,
                image: hihu01,
                description: 'お肌へのケアを行います。化粧水やパウダーなどで肌に皮膜を作り、より負荷を軽減するケアを行います',
            },
            {
                id: 2,
                image: hihu02,
                description: 'ファンデーション、日焼け止め、余分な皮脂などを拭き取ります。',
            },
            {
                id: 3,
                image: hihu03,
                description: 'お肌への負担を軽減するために、ジェルを塗布します。',
            },
            {
                id: 4,
                image: hihu04,
                description: 'お痛みなどを確認しながら照射をしていきます。お客様のお肌の状態、脂肪の厚みやたるみ具合を確認しながら適正なレベル出力で施術を進めます。',
            },
        ]
    },
    nextEMS: {
        id: 2,
        title: '次世代EMS',
        cards: [
            {
                id: 1,
                image: nextEMS01,
                description: 'お客様のご希望の施術パーツにバンテージを準備します。この時、貴金属類や電子機器等は全て外していただきます。',
            },
            {
                id: 2,
                image: nextEMS02,
                description: '筋肉の位置を確認しながら機械を装着する位置を決めます。',
            },
            {
                id: 3,
                image: nextEMS03,
                description: '機械の位置を最終確認いたします。お客様の筋肉量に合わせてレベル設定を行い、痛くないかどうか一度テストさせていただきます。',
            },
            {
                id: 4,
                image: nextEMS04,
                description: '適正レベルになったことを確認後、スタートしていきます。',
            },
        ]
    },
    waxHairRemoval: {
        id: 3,
        title: 'ワックス脱毛',
        cards: [
            {
                id: 1,
                image: waxHair01,
                description: 'ワックスの温度が熱すぎないか確認します。',
            },
            {
                id: 2,
                image: waxHair02,
                description: '脱毛箇所のお肌を清潔にしたあと、お肌への負担を軽減するためにオイルや保護パウダーを塗布し疑似皮脂膜を作ります。',
            },
            {
                id: 3,
                image: waxHair03,
                description: '温かいワックスを体毛の生えたお肌に塗布します。上からワックスペーパーを乗せなじませます。',
            },
            {
                id: 4,
                image: waxHair04,
                description: 'ワックス剤を体毛に絡ませ一気に引き剥がします。多少残ってしまう細かい毛はピンセットで一本ずつ抜いていきます。',
            },
        ]
    },
    laserHairRemoval: {
        id: 4,
        title: '光脱毛',
        cards: [
            {
                id: 1,
                image: laserHair01,
                description: 'お客様のお肌の状態を確認します。施術箇所に剃り残しなどがあれば先に電動シェーバーで剃毛させていただきます。（※別途追加料金）',
            },
            {
                id: 2,
                image: laserHair02,
                description: 'お客様の目元にアイガードを装着します。',
            },
            {
                id: 3,
                image: laserHair03,
                description: '脱毛箇所にお肌を保護するためのジェルをまんべんなく塗布します。',
            },
            {
                id: 4,
                image: laserHair04,
                description: '適正レベルを確認しながら照射をスタートしていきます。',
            },
        ]
    },
    
}

    const [plan, setPlan] = useState({
        active: 'hihu',
        menuDetail: menuDetailList.hihu,
      });
    
      const handlePlan = (plan) => {
        if (plan === 'hihu') {
          setPlan({
            active: 'hihu',
            menuDetail: menuDetailList.hihu,
          });
        }
        if (plan === 'nextEMS') {
          setPlan({
            active: 'nextEMS',
            menuDetail: menuDetailList.nextEMS,
          });
        }
        if (plan === 'laserHairRemoval') {
          setPlan({
            active: 'laserHairRemoval',
            menuDetail: menuDetailList.laserHairRemoval,
          });
        }
        if (plan === 'waxHairRemoval') {
          setPlan({
            active: 'waxHairRemoval',
            menuDetail: menuDetailList.waxHairRemoval,
          });
        }
      };

    return (
        <Box as="section" variant="section.menu" sx={styles.menu} id="detail">
            <Container>
                <SectionHeading title="Detail" />
                <StaticImage src="../assets/images/detailBanner.jpg" variant="sectionBanner" />

                <Flex sx={styles.priceSwitcher} id="treatment">
                    <Button
                        variant="text"
                        onClick={() => handlePlan('hihu')}
                        className={`${plan.active === 'hihu' ? 'active' : ''}`}
                    >
                        ハイフ
                    </Button>
                    <Button
                        variant="text"
                        onClick={() => handlePlan('nextEMS')}
                        className={`${plan.active === 'nextEMS' ? 'active' : ''}`}
                    >
                        次世代EMS
                    </Button>
                    <Button
                        variant="text"
                        onClick={() => handlePlan('waxHairRemoval')}
                        className={`${plan.active === 'waxHairRemoval' ? 'active' : ''}`}
                    >
                        ワックス脱毛
                    </Button>
                    <Button
                        variant="text"
                        onClick={() => handlePlan('laserHairRemoval')}
                        className={`${plan.active === 'laserHairRemoval' ? 'active' : ''}`}
                    >
                        光脱毛
                    </Button>
                </Flex>

                <Box sx={{mb: 6, maxWidth: '1060px', mx: 'auto'}}>
                    <Flex sx={{mb: 3}}>
                        <Heading as="h4" sx={{fontWeight: 700}}>{plan?.menuDetail?.title}</Heading>
                        <Divider sx={{marginBottom: '22px'}}/>
                    </Flex>
                    <Text as="p" sx={{fontSize: [12, 16], textAlign: 'left', ml: '14px'}}>施設の紹介と、ハイフ、EMS、ワックス脱毛、光脱毛の施術のフローを紹介いたします。<br/>施術の紹介写真は一例になります。</Text>
                </Box>
                </Container>

                <SimpleSlider cards={plan?.menuDetail?.cards}/>
                <Box sx={styles.campaigns}>
                    <Campaigns />
                </Box>
        </Box>
    )
}

export default Detail

const styles = {
    menu: {
        h4: {
            fontSize: [20, '28px'],
            lineHeight: 1.28,
            color: 'heading',
            marginLeft: '14px',
            alignSelf: 'center',
            backgroundImage: `-webkit-linear-gradient(left, #416585 0%, #FF8B81 85%)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
        },
        slider: {
            height: '400px',
            background: 'linear-gradient(99.22deg, rgba(217, 237, 255, 0.5) -0.11%, rgba(255, 232, 230, 0.5) 68.8%)',
            display: 'flex',
            scrollSnapType: 'x mandatory',
            WebkitOverflowScrolling: 'touch',
            overflowX: 'scroll',
            minWidth: 'unset',
            scrollBehavior: 'smooth',
        },
    },
    sliderImage: {
        maxWidth: 'unset',
        minWidth: 'unset',
    },
    sliderCard: {
        scrollSnapAlign: 'center',
        maxWidth: 350,
        minWidth: 'unset',
        ml: 260,
        mt: 48,
        '&:first-of-type': {
            marginLeft: 'calc(50% - 175px)',
        },
        '&:last-of-type': {
            marginRight: 'calc(50% - 175px)',
        },
    },
    sliderNav: {
        position: 'absolute',
        right: '0',
        bottom: '0',
        left: '0',
        textAlign: 'center',
    },
    sliderNavButton: {
        display: 'inline-flex',
        width: '1.5rem',
        height: '1.5rem',
        background: 'pink',
        textDecoration: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        margin: '0 0 0.5rem 0',
        position: 'relative',
    },
    heading: {
      mb: [60, null, null, 50],
    },
    priceSwitcher: {
      backgroundColor: 'white',
      borderRadius: '5px',
      border: `1px solid ${rgba('#fff', 0.2)}`,
      margin: ['32px auto 40px', null, null, '32px auto 50px'],
      maxWidth: 1060,
      position: 'relative',
      transition: 'background .5s ease-out',
      p: 2,
      button: {
        width: 530,
        maxWidth: ['83px', '530px'],
        minHeight: '48px',
        background: 'white',
        px: ['23px', '47px'],
        paddingTop: '13px',
        fontWeight: 700,
        alignItems: 'baseline',
        justifyContent: 'center',
        borderBottom: '8px solid #E6F0FA',
        fontSize: ['12px', '16px'],
        letterSpacing: '0.03em;',
        color: '#61A2DD',
        transition: 'background .5s ease-out',
        '&.active': {
            backgroundColor: '#E6F0FA',
            borderBottom: `8px solid`,
            borderImage: 'linear-gradient(99.22deg, #BFE1FF -0.11%, #FFBAB4 68.8%);',
            borderImageSlice: 1,
            borderRadius: 'unset',
            transition: 'background .5s ease-out',
          },
          ':focus': {
            outline: '0 none',
          },
      },
    },
    campaigns: {
        width: '100%',
        maxWidth: ['100%', '100%', '1060px'],
        margin: ['auto', '150px auto 0'],
        padding: ['0 20px', '0'],
    }
  };
  