/** @jsx jsx */
import { jsx, Flex, Text, Link } from 'theme-ui';
import { StaticImage } from "gatsby-plugin-image"

const Campaigns = ({color, src, title, description, mWidth}) => {
  return(
    <Flex sx={styles.campaigns}>
      <div sx={styles.campaignPC}>
        <StaticImage sx={styles.banner} src="../assets/images/discount.png" />
      </div>
      <Link sx={styles.campaignSP} href="https://liff.line.me/1645278921-kWRPP32q?openerPlatform=native&openerKey=urlSchema%3Aexternal&accountId=024djuvi#mst_challenge=8sy0TCmk1ybr2pvF9EtHWlOpkcehyt3w5bmLJvbFQYk" target="_blank">
        <StaticImage sx={styles.banner} src="../assets/images/discountSp.png" />
      </Link>
      <Flex sx={styles.conversion}>
        <Text>
          施術内容や気になることについて、事前にご相談いただくことも可能です。まずはお気軽にWebにてカウンセリングをお申込みくださいませ。
        </Text>
        <Link sx={styles.link} href="https://timerex.net/s/sachi_s_marmotbeauty/dc79ea76" target="_blank">
          <StaticImage sx={styles.button} src="../assets/images/schedule.png" />
        </Link>
      </Flex>
    </Flex>
  );
};

export default Campaigns;

const styles = {
  campaigns: {
    justifyContent: 'space-between',
    flexDirection: ['column', 'column', 'row'],
    alignItems: 'flex-end',
  },
  campaignPC: {
    width: '600px',
    display: ['none', 'none', 'block']
  },
  campaignSP: {
    width: '100%',
    display: ['block', 'block', 'none']
  },
  banner: {
    boxShadow: '0px 7px 4px 0px #E6F0FA33',
    borderRadius: '4px'
  },
  conversion: {
    flexDirection: 'column', 
    alignSelf: 'end', 
    margin: ['60px 0 0 0', '0 60px 0 10px'], 
    width: ['100%', '100%', '300px'],
    fontSize: '16px',
    lineHeight: '2',
    textAlign: 'left'
  },
  link: {
    margin: ['17px 0 0 0', '0'], 
  },
  button: {
    boxShadow: '0px 5px 4px 0px #FFAEA71A',
    borderRadius: '4px'
  }
}