/** @jsx jsx */
import { jsx, Box, Container, Text, Image, Heading, Flex} from 'theme-ui'
import { Divider } from '@theme-ui/components';
import SectionHeading from '../components/section-heading'
import { StaticImage } from 'gatsby-plugin-image'
import Map from '../components/googleMap'
import brandSquareIcon from '../assets/images/brandSquareIcon.svg'

const Access = () => {
    return (
        <Box as="section" sx={styles.access} id="access">
            <Container>
                <SectionHeading title="Access" />
            </Container>

            <Map/>

            <Container>
                <Box>
                    <Flex sx={{alignItems: 'center'}}>
                        <Heading as="h4">Street address</Heading>
                        <Divider />
                    </Flex>
                    <Flex sx={{width: '100%', alignItems: 'center'}}>
                        <Image src={brandSquareIcon} sx={{paddingLeft: [0, '20px'], height: '100%',}} />
                        <Box sx={styles.addressDetail}>
                            <Text sx={{fontWeight: '700', fontSize: [14, 16]}}>MarMot! Beauty</Text>
                            <Text sx={{letterSpacing: ['-0.5px', '0.3em'], fontSize: [14, 16], fontFamily: '"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体"',}}>〒106-0032　東京都港区六本木3-6-19　ザ・パークメゾン六本木A棟</Text>
                            <Text sx={{letterSpacing: ['normal', '0.2em'], fontSize: [12, 14]}}>日比谷線六本木駅５番出口より徒歩4分 / 南北線六本木駅一丁目より徒歩7分</Text>
                        </Box>
                    </Flex>
                </Box>

                <Box sx={{my: 6}}>
                    <Text sx={{marginBottom: ['24px', '16px']}}>決済ブランドのお問い合わせ</Text>
                    <div sx={styles.paymentTable}>
                        <div sx={styles.paymentTableItem}>
                            <Text sx={styles.paymentTableItemHeading}>クレジットカード</Text>
                            <div sx={styles.paymentTableItemBody}>
                                ・VISA<br/>
                                ・MASTER<br/>
                                ・AMEX<br/>
                                ・DINERS CLUB<br/>
                                ・DISCOVER<br/>
                                ・JCB
                            </div>
                        </div>
                        <div sx={styles.paymentTableItem}>
                            <Text sx={styles.paymentTableItemHeading}>電子マネー</Text>
                            <div sx={styles.paymentTableItemBody}>
                                ・QuickPay<br/>
                                ・ApplePay<br/>
                                ・ID
                            </div>
                        </div>
                        <div sx={styles.paymentTableItem}>
                            <Text sx={styles.paymentTableItemHeading}>交通系IC</Text>
                            <div sx={styles.paymentTableItemBody}>
                                ・SUICA<br/>
                                ・Pasmo<br/>
                                他７種
                            </div>
                        </div>
                        <div sx={styles.paymentTableItem}>
                            <Text sx={styles.paymentTableItemHeading}>QR決済</Text>
                            <div sx={styles.paymentTableItemBody}>
                                ・PayPay
                            </div>
                        </div>
                    </div>
                    <Text sx={styles.paymentTableNotes}>※請求書払いもご利用いただけます</Text>
                </Box>
            </Container>
            <Box sx={{
                mt: ['40px', '80px', '150px'],
                mb: ['40px', '80px', '500px']
            }}>
                <Flex 
                    sx={{
                        alignItems: 'center', 
                        padding: '0 20px',
                        display: ['flex', 'flex', 'none']
                    }}
                >
                    <Heading as="h4">Store information</Heading>
                    <Divider />
                </Flex>
                <Box sx={styles.storeInfo}>
                    <Box sx={{
                        maxWidth: '571px',
                        padding: ['20px', '25px 20px 40px 50px'],
                        margin: '0 auto'
                    }}>
                        <Flex sx={{
                            alignItems: 'center', 
                            display: ['none', 'none', 'flex']
                        }}>
                            <Heading as="h4">Store information</Heading>
                            <Divider />
                        </Flex>

                        <div sx={styles.description}>
                            <Flex as="dl">
                                <Text as="dt">営業時間</Text>
                                <Text as="dd">11:00 - 20:00</Text>
                            </Flex>
                            <Flex as="dl">
                                <Text as="dt">定休日</Text>
                                <Text as="dd">日曜・第二第四月曜日</Text>
                            </Flex>
                            <Flex as="dl">
                                <Text as="dt">お問い合わせ</Text>
                                <Text as="dd">info@tanpan.co.jp</Text>
                            </Flex>
                        </div>
                        <StaticImage src="../assets/images/accessMap.png"
                            sx={{
                                position: 'absolute',
                                top: '-40px',
                                left: '-540px',
                                maxWidth: '816px',
                                display: ['none', 'none', 'block']
                            }}
                        />
                        <StaticImage src="../assets/images/accessMapSp.png"
                            sx={{
                                mb: '30px',
                                display: ['block', 'block', 'none']
                            }}
                        />
                        <Text sx={{
                            fontSize: '12px',
                            display: ['block', 'block', 'none']
                        }}>
                            定食屋さんの暖簾が建物が目印です。<br/>建物正面入り口、左手側に、インターフォンが2つ設置されています。「A」と書いてある、インターフォンを押して頂くと、ゲートを解錠いたします。
                        </Text>
                    </Box>
                    <Text sx={{
                            fontSize: '12px',
                            position: "absolute",
                            bottom: "-272px",
                            marginLeft: "50px",
                            maxWidth: "407px",
                            letterSpacing: '0.2em',
                            lineHeight: '260%',
                            display: ['none', 'none', 'block']
                        }}>
                            定食屋さんの暖簾が建物が目印です。<br/>建物正面入り口、左手側に、インターフォンが2つ設置されています。「A」と書いてある、インターフォンを押して頂くと、ゲートを解錠いたします。
                    </Text>
                </Box>
            </Box>
        </Box>
    )
}

const styles = {
    access: {
        h4: {
            fontSize: ['18px', '32px'],
            lineHeight: 1.28,
            color: 'heading',
            marginBottom: '20px',
            backgroundImage: `-webkit-linear-gradient(left, #416585 0%, #FF8B81 85%)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
        }
    },
    addressDetail: {
        width: '100%',
        marginLeft: ['19px', '5.4%'],
        padding: [0],
        div: {
            marginBottom: '14px'
        }
    },
    storeInfo: {
        maxWidth: ['100%', '100%', '55vw'],
        height: 'auto',
        marginLeft: 'auto',
        position: 'relative',
        // padding: ['20px', '25px 0 40px 30px'],
        background: 'linear-gradient(99.22deg, #D9EDFF -0.11%, #FFE8E6 68.8%)',
    },
    description: {
        marginTop: [0, '32px'],
        marginBottom: ['20px', 0],
        dl: {
            display: 'flex',
        },
        dt: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: ['auto', '200px'],
            '::after': {
                content: "':'",
                margin: ['0 11px', '0 42px'],
            }
        },
        dd: {
        }
    },
    paymentTable: {
        display: 'grid',
        gridTemplateColumns: ['repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(4, 1fr)'],
        gridTemplateRows: ['repeat(2, 1fr)', 'repeat(2, 1fr)', '1fr'],
        gap: '1px',
        background: '#FFD5D1',
        border: '1px solid #FFD5D1'
    },
    paymentTableItem: {
        display:'block',
        overflow: 'hidden'
    },
    paymentTableItemHeading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: '700',
        textAlign: 'center',
        backgroundColor: '#FFECEB',
        color: '#62788D',
        height: '40px',
        fontFamily: '"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体"'
    },
    paymentTableItemBody: {
        display:'block',
        color: '#62788D',
        padding: '16px calc(40 / 240 * 100%)',
        fontFamily: '"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体"',
        background: '#fff',
        height: '100%'
    },
    paymentTableNotes: {
        fontSize: '12px',
        textAlign: 'right',
        letterSpacing: '0.1em',
        color: '#A1B0BE',
        marginTop: '10px'
    }
}

export default Access