/** @jsx jsx */
import { jsx, Image, Heading, Text, Box, Flex } from 'theme-ui';
import {useRef, useState} from 'react'
import PlusIcon from '../plusIcon';
import { useInView } from 'react-intersection-observer'
import { keyframes } from '@emotion/react'
import { Divider } from '@theme-ui/components';
import tickets from 'assets/images/tickets.png';

const wave = keyframes`
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`

const CouponAccordion = ({info, theme, ...props}) => {
    const [setActive, setActiveState] = useState("");
    const [setHight, setHightState] = useState("0px");
    const [setRotate, setRotateState] = useState("")

    const content = useRef(null);

    function toggleAccordion() {
        setActiveState(setActive === "" ? "active" : "");
        setHightState(
            setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
        );
        setRotateState(
            setActive === "active" ?  "" : "rotate(135deg)"
        )
    }

    const [ref, inView] = useInView({
        rootMargin: "-150px 0px",
        triggerOnce: true,
    });
    
    return (
        <div 
            ref={ref} 
            {...props} 
            sx={styles.accordion__section}
            css={{
                opacity: inView ? '1' : '0',
                animation: inView ? `${wave} cubic-bezier(.17,.67,.32,.1) .3s` : 0,
            }}
        >
            <button 
                sx={{
                    variant: 'accordion',
                    borderColor: theme.border,
                    backgroundSize: 'cover',
                    backgroundPosition: ['center', 'unset'],
                    background: 'linear-gradient(89.84deg, #F2F9FF 2.59%, #FCECEB 48.09%, rgba(255, 232, 230, 0.44) 64.18%, rgba(255, 232, 230, 0.44) 79.94%, #FFFFFF 90.49%)',
                }}
                className={`${setActive}`} 
                onClick={toggleAccordion}
            >
                <Image
                    src={tickets}
                    sx={{
                        minWidth: 'unset',
                        height: '100%',
                        maxWidth: ['67px', '100%']
                    }}
                />
                <Box sx={styles.accordion__detail}>
                    <Heading sx={styles.accordion__title}>回数券</Heading>
                    <Text sx={styles.accordion__description}>
                    全メニュー3回券、ヒゲ5回券は有効期限半年となります。その他の回数券は全て有効期限1年間となります<br/>
                    有効期限は翌年に繰り越せません。当日キャンセルをされた場合、一回分の消費となります<br/>
                    </Text>
                </Box>
                <PlusIcon fill={theme.plusIcon} sx={{transform: setRotate}}/>
            </button>
            <div ref={content} sx={{
                backgroundColor: 'white',
                overflow: 'hidden',
                transition: 'max-height 0.6s ease',
                border: '2px solid',
                borderColor: theme.menuBorder,
                borderTop: 'none',
                marginTop: '-2px',
            }} style={{maxHeight: `${setHight}`}}>
                <Text sx={{
                    padding: '15px 10px',
                    fontSize: ['12px', '16px'],
                    display: ['block', 'block', 'none']
                }}>
                    全メニュー3回券、ヒゲ5回券は有効期限半年となります。その他の回数券は全て有効期限1年間となります<br/>
                    有効期限は翌年に繰り越せません。当日キャンセルをされた場合、一回分の消費となります
                </Text>
                <div sx={{
                    display: 'flex',
                    padding: ['15px 13px', '18px 32px'], 
                    alignItems: 'center',
                    backgroundColor: theme.warningBack,
                }}>
                    <Text 
                        sx={{
                            fontWeight:700, 
                            color: theme.fontColor, 
                            fontSize: ['12px', '14px'],
                        }}>
                        注意事項
                    </Text>
                    <Text sx={{
                        fontSize: ['12px', '14px'],
                        flex: '1', 
                        marginLeft: '30px'
                        }}>
                        ※使用期限がございます。<br/>
                        ※回数券の消費はご本人様に限ります。他のお客様へのプレゼントをご希望のお客様はご相談ください。<br/>
                    </Text>
                    <div sx={{
                        display: ['none', 'none', 'block']
                    }}>
                    </div>
                </div>
                <div sx={{padding: ['15px 12px', '32px']}}>
                    <Flex>
                        <Heading sx={styles.accordion__menuHead}>ハイフ / HIFU</Heading>
                        <Divider />
                    </Flex>
                    <div sx={styles.accordion__menuCardWrap}>
                            <div sx={{
                                height: '128px',
                                padding: ['10px', '15px'],
                                backgroundColor: theme.menu,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }} >
                                <div 
                                    sx={{
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        marginRight: '43px', 
                                        flex: 1
                                    }}>
                                    <Text 
                                        sx={{
                                            fontSize: ['14px', '18px'] ,
                                            fontWeight: 700, 
                                            letterSpacing: '0.03em',
                                            borderBottom: '4px solid',
                                            borderColor: theme.menuBorder,
                                            color: '#62788D',
                                        }}>
                                        全顔3回券
                                    </Text>
                                    <Text sx={{
                                        // fontSize: '12px',
                                        // my: [0, '12px'],
                                        mt: ['4px', null],
                                        // color: '#62788D',
                                        fontSize: ['14px', '18px'] ,
                                        fontWeight: 700, 
                                        letterSpacing: '0.03em',
                                        color: '#62788D',
                                    }}>
                                        全顔6回券
                                    </Text>
                                </div>
                                <div>
                                    <Heading sx={{
                                        fontSize: ['16px', '22px'], 
                                        fontFamily: '"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体"',
                                        color: '#62788D',
                                    }}>
                                        ￥39,000 -<br/>
                                        ￥66,000 -
                                    </Heading>
                                </div>
                            </div>
                        </div>

                    <Flex>
                        <Heading sx={styles.accordion__menuHead}>次世代EMS（電磁パルス痩身）</Heading>
                        <Divider />
                    </Flex>
                    <div sx={styles.accordion__menuCardWrap}>
                            <div sx={{
                                height: '128px',
                                padding: ['10px', '15px'],
                                backgroundColor: theme.menu,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }} >
                                <div 
                                    sx={{
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        marginRight: '43px', 
                                        flex: 1
                                    }}>
                                    <Text 
                                        sx={{
                                            fontSize: ['14px', '18px'] ,
                                            fontWeight: 700, 
                                            letterSpacing: '0.03em',
                                            borderBottom: '4px solid',
                                            borderColor: theme.menuBorder,
                                            color: '#62788D',
                                            fontFamily: '"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体"',
                                        }}>
                                        15分×12回券
                                    </Text>
                                    <Text sx={{
                                        mt: ['4px', null],
                                        fontSize: ['14px', '18px'] ,
                                        fontWeight: 700, 
                                        letterSpacing: '0.03em',
                                        color: '#62788D',
                                        fontFamily: '"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体"',
                                    }}>
                                        15分×24回券
                                    </Text>
                                </div>
                                <div>
                                    <Heading sx={{
                                        fontSize: ['16px', '22px'], 
                                        fontFamily: '"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体"',
                                        color: '#62788D',
                                    }}>
                                        ￥66,000 -<br/>
                                        ￥120,000 -
                                    </Heading>
                                </div>
                            </div>
                        </div>


                    <Flex>
                        <Heading sx={styles.accordion__menuHead}>光脱毛</Heading>
                        <Divider />
                    </Flex>
                    <div sx={styles.accordion__menuCardWrap}>
                            <div sx={{
                                height: '128px',
                                padding: ['10px', '15px'],
                                backgroundColor: '#F2F9FF',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                fontFamily: 'YuGothic,"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体","ヒラギノ角ゴ Pro W3","メイリオ",sans-serif'
                            }} >
                                <div 
                                    sx={{
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        marginRight: '43px', 
                                        flex: 1
                                    }}>
                                    <Text 
                                        sx={{
                                            fontSize: ['14px', '18px'] ,
                                            fontWeight: 700, 
                                            letterSpacing: '0.03em',
                                            borderBottom: '4px solid',
                                            borderColor: '#C2DBF2',
                                            color: '#62788D',
                                        }}>
                                        VO 3回券
                                    </Text>
                                    <Text sx={{
                                        // fontSize: '12px',
                                        // my: [0, '12px'],
                                        mt: ['4px', null],
                                        // color: '#62788D',
                                        fontSize: ['14px', '18px'] ,
                                        fontWeight: 700, 
                                        letterSpacing: '0.03em',
                                        color: '#62788D',
                                    }}>
                                        VO 6回券
                                    </Text>
                                </div>
                                <div>
                                    <Heading sx={{
                                        fontSize: ['16px', '22px'], 
                                        fontFamily: '"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体"',
                                        color: '#62788D',
                                    }}>
                                        ￥27,000 -<br/>
                                        ￥48,000 -
                                    </Heading>
                                </div>
                            </div>

                            <div sx={{
                                height: '128px',
                                padding: ['10px', '15px'],
                                backgroundColor: '#F2F9FF',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                fontFamily: 'YuGothic,"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体","ヒラギノ角ゴ Pro W3","メイリオ",sans-serif'
                            }} >
                                <div 
                                    sx={{
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        marginRight: '43px', 
                                        flex: 1
                                    }}>
                                    <Text 
                                        sx={{
                                            fontSize: ['14px', '18px'] ,
                                            fontWeight: 700, 
                                            letterSpacing: '0.03em',
                                            borderBottom: '4px solid',
                                            borderColor: '#C2DBF2',
                                            color: '#62788D',
                                        }}>
                                        ヒゲ 5回券
                                    </Text>
                                    <Text sx={{
                                        // fontSize: '12px',
                                        // my: [0, '12px'],
                                        mt: ['4px', null],
                                        // color: '#62788D',
                                        fontSize: ['14px', '18px'] ,
                                        fontWeight: 700, 
                                        letterSpacing: '0.03em',
                                        color: '#62788D',
                                    }}>
                                        ヒゲ 10回券
                                    </Text>
                                </div>
                                <div>
                                    <Heading sx={{
                                        fontSize: ['16px', '22px'], 
                                        fontFamily: '"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体"',
                                        color: '#62788D',
                                    }}>
                                        ￥35,000 -<br/>
                                        ￥60,000 -
                                    </Heading>
                                </div>
                            </div>

                            <div sx={{
                                height: '128px',
                                padding: ['10px', '15px'],
                                backgroundColor: '#F2F9FF',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                fontFamily: 'YuGothic,"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体","ヒラギノ角ゴ Pro W3","メイリオ",sans-serif'
                            }} >
                                <div
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginRight: '43px',
                                        flex: 1
                                    }}>
                                    <Text
                                        sx={{
                                            fontSize: ['14px', '18px'] ,
                                            fontWeight: 700,
                                            letterSpacing: '0.03em',
                                            borderBottom: '4px solid',
                                            borderColor: '#C2DBF2',
                                            color: '#62788D',
                                        }}>
                                        脚オール 3回券
                                    </Text>
                                    <Text sx={{
                                        // fontSize: '12px',
                                        // my: [0, '12px'],
                                        mt: ['4px', null],
                                        // color: '#62788D',
                                        fontSize: ['14px', '18px'] ,
                                        fontWeight: 700,
                                        letterSpacing: '0.03em',
                                        color: '#62788D',
                                    }}>
                                        脚オール 5回券
                                    </Text>
                                </div>
                                <div>
                                    <Heading sx={{
                                        fontSize: ['16px', '22px'],
                                        fontFamily: '"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体"',
                                        color: '#62788D',
                                    }}>
                                        ￥30,000 -<br/>
                                        ￥47,500 -
                                    </Heading>
                                </div>
                            </div>

                            <div sx={{
                                height: '128px',
                                padding: ['10px', '15px'],
                                backgroundColor: '#F2F9FF',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                fontFamily: 'YuGothic,"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体","ヒラギノ角ゴ Pro W3","メイリオ",sans-serif'
                            }} >
                                <div 
                                    sx={{
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        marginRight: '30px',
                                        flex: 1
                                    }}>
                                    <Text 
                                        sx={{
                                            fontSize: ['14px', '18px'] ,
                                            fontWeight: 700, 
                                            letterSpacing: '0.03em',
                                            borderBottom: '4px solid',
                                            borderColor: '#C2DBF2',
                                            color: '#62788D',
                                        }}>
                                        全身（フェイシャル除く）3回券
                                    </Text>
                                    <Text sx={{
                                        // fontSize: '12px',
                                        // my: [0, '12px'],
                                        mt: ['4px', null],
                                        // color: '#62788D',
                                        fontSize: ['14px', '18px'] ,
                                        fontWeight: 700, 
                                        letterSpacing: '0.03em',
                                        color: '#62788D',
                                    }}>
                                        全身（フェイシャル除く）6回券
                                    </Text>
                                </div>
                                <div>
                                    <Heading sx={{
                                        fontSize: ['16px', '22px'], 
                                        fontFamily: '"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体"',
                                        color: '#62788D',
                                    }}>
                                        ￥120,000 -<br/>
                                        ￥216,000 -
                                    </Heading>
                                </div>
                            </div>
                        </div>


                    <Flex>
                        <Heading sx={styles.accordion__menuHead}>ワックス + 光脱毛</Heading>
                        <Divider />
                    </Flex>
                    <div sx={styles.accordion__menuCardWrap}>
                            <div sx={{
                                height: '128px',
                                padding: ['10px', '15px'],
                                backgroundColor: '#F2F9FF',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                fontFamily: 'YuGothic,"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体","ヒラギノ角ゴ Pro W3","メイリオ",sans-serif'
                            }} >
                                <div 
                                    sx={{
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        marginRight: '43px', 
                                        flex: 1
                                    }}>
                                    <Text 
                                        sx={{
                                            fontSize: ['14px', '18px'] ,
                                            fontWeight: 700, 
                                            letterSpacing: '0.03em',
                                            borderBottom: '4px solid',
                                            borderColor: '#C2DBF2',
                                            color: '#62788D',
                                        }}>
                                        VIO ワックス + VO光 3回券
                                    </Text>
                                    <Text sx={{
                                        // fontSize: '12px',
                                        // my: [0, '12px'],
                                        mt: ['4px', null],
                                        // color: '#62788D',
                                        fontSize: ['14px', '18px'] ,
                                        fontWeight: 700, 
                                        letterSpacing: '0.03em',
                                        color: '#62788D',
                                    }}>
                                        VIO ワックス + VO光 6回券
                                    </Text>
                                </div>
                                <div>
                                    <Heading sx={{
                                        fontSize: ['16px', '22px'], 
                                        fontFamily: '"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体"',
                                        color: '#62788D',
                                    }}>
                                        ￥40,000 -<br/>
                                        ￥72,000 -
                                    </Heading>
                                </div>
                            </div>
                        </div>

                </div>
            </div>
        </div>       
    )
}

export default CouponAccordion

const styles = {
    accordion__section: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: ['10px', '24px'],
    },
    accordion__title: {
        fontWeight: 600,
        fontSize: ['16px', '24px'],
        color: '#4F6172',
    },
    accordion__detail: {
        paddingLeft:'8px',
    },
    rotate: {
        transform: 'rotate(90deg)',
    },
    accordion__text: {
        fontWeight: 400,
        fontSize: 14,
        padding: '10px',
    },
    accordion__description: {
        fontSize: 14,
        width: '54%',
        lineHeight: '18.2px',
        display: ['none', 'none', 'none', 'block'],
        color: '#4F6172',
    },
    accordion__menuCardWrap: {
        display: 'grid',
        gridGap: '16px',
        gridTemplateColumns: ['1fr', '1fr', '1fr', '1fr 1fr'],
        mb: ['32px'],
        span: {
            fontSize: [12, 16],
            textAlign: "center", 
            display: "block", 
            fontWeight: "bold"
        },
        h2: {
            textAlign: 'right'
        }
    },
    accordion__menuHead: {
        fontSize: ['16px', '18px'],
        lineHeight: 1.28,
        alignSelf: 'center',
        backgroundImage: `-webkit-linear-gradient(320deg, #416585 0%, #FF8B81 60%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        marginBottom: '20px',
        fontWeight: 700,
    }   
}