/** @jsx jsx */
import { jsx, Text, Image, Card } from 'theme-ui';
import { Component } from "react";
import Slider from "react-slick";

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      speed: 500,
      slidesToShow: 1,
      centerMode: true,
      centerPadding: '400px',
      slidesToScroll: 1,
      infinite: false,
      // autoplay: true,
      autoplaySpeed: 4500,
      pauseOnHover: true,
      arrows: false,
      focusOnSelect: true,
      appendDots: dots => (
        <div
          sx={{
            position: 'absolute',
            bottom: ['-46px', '-94px'],
          }}
        >
          <ul style={{ margin: "0px", padding: '0px' }}> {dots} </ul>
        </div>
      ),
      responsive: [
        {
          breakpoint: 2500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            centerPadding: '600px',
          }
        },
        {
          breakpoint: 1800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            centerPadding: '500px',
          }
        },
        {
          breakpoint: 1680,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            centerPadding: '450px',
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            // initialSlide: 2,
            centerPadding: '300px',
          }
        },
        {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              centerMode: true,
              centerPadding: '38px',
            }
        },
      ]
    };
    return (
      <div sx={styles.slider}>
        <Slider {...settings}>
            {this.props?.cards?.map((item, index) => (
                <div key={index}>
                    <Card
                        sx={styles.sliderCard}>
                        <Image src={item.image} sx={styles.sliderImage}/>
                        <Text sx={{
                            fontSize: [12, 16],
                            marginTop: ['15px', 0]
                        }}>{item.description}</Text>
                    </Card>
                </div>
            ))}
        </Slider>
      </div>
    );
  }
}

const styles = {
    slider: {
        height: ['282px', '400px'],
        background: 'linear-gradient(99.22deg, rgba(217, 237, 255, 0.5) -0.11%, rgba(255, 232, 230, 0.5) 68.8%)',
        mb: ['100px', 0]
    },
    sliderImage: {
        maxWidth: ['256px', 'unset'],
        minWidth: 'unset',
    },
    sliderCard: {
        scrollSnapAlign: 'center',
        maxWidth: [256, 350],
        minWidth: 'unset',
        ml: 260,
        mt: [20, 48],
        '&:first-of-type': {
            marginLeft: 'calc(50% - 175px)',
        },
        '&:last-of-type': {
            marginRight: 'calc(50% - 175px)',
        },
    },
}