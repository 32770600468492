import React from 'react'
import menuHihu from 'assets/images/menuHihu.png';
import menuEMS from 'assets/images/menuEMS.png'
import laserHair from 'assets/images/laserHair.png';
import waxHair from 'assets/images/waxHair.png';
import laserAndWaxHair from 'assets/images/laserAndWaxHair.png';
import tickets from 'assets/images/tickets.png';
import hifuBack from 'assets/images/hifuBack.png'
import emsBack from 'assets/images/emsBack.png'
import laserBack from 'assets/images/laserBack.png'
import waxBack from 'assets/images/waxBack.png'
import waxLaserBack from 'assets/images/waxLaserBack.png'
import couponBack from 'assets/images/couponBack.png'

export default {
    sliming: [
        {
        id: 1,
        title: 'ハイフ / HIFU',
        description: 'たるみの改善とリフトアップ効果に加え、小顔効果を得られる施術です。ほとんど痛みを感じずに施術を行うことが可能。超音波を一点に収束させ脂肪細胞の数を物理的に減らし、効果的にサイズダウンが目指せるダウンタイムがない脂肪吸引と言われています。',
        icon: menuHihu,
        backImg: hifuBack,
        modalLink: '/hifuModal/',
        warning: (
            <div>
                ※生理中はウエストの施術不可<br/>
                ※妊娠中の方、産後授乳されている方は施術不可<br/>
                ※顔ハイフをご利用のお客様はお化粧を落としていただきます。施術後の軽いお化粧直しは可能です。<br/>
                ※価格は全て税別価格です<br/>
                ※ビジター価格＝前回来店より2ヶ月以上経過したお客様
            </div>
        ),
        content: [
            {
                id: 1,
                menuTitle: '全顔 (首含む)',
                menuTime: '所要時間 :  75分目安',
                menuPrices: [{
                    text: 'ビジター価格',
                    price: '￥14,000 -'
                },{
                    text: 'リピーター価格',
                    price: '￥12,000 -'
                }],
            },
            {
                id: 2,
                menuTitle: 'フェイスライン、ほうれい線のみ',
                menuTime: '所要時間 :  60分目安',
                menuPrices: [{
                    text: 'ビジター価格',
                    price: '￥9,000 -'
                },{
                    text: 'リピーター価格',
                    price: '￥7,000 -'
                }],
            },
            {
                id: 3,
                menuTitle: 'ウエスト周り（お腹EMS30分付き）',
                menuTime: '所要時間 :  90分目安',
                menuPrices: [{
                    text: 'ビジター価格',
                    price: '￥27,000 -'
                },{
                    text: 'リピーター価格',
                    price: '￥25,000 -'
                }],
            },
            {
                id: 4,
                menuTitle: '二の腕（二の腕EMS15分付き）',
                menuTime: '所要時間 :  45分目安',
                menuPrices: [{
                    text: 'ビジター価格',
                    price: '￥10,000 -'
                },{
                    text: 'リピーター価格',
                    price: '￥8,000 -'
                }],
            },
            {
                id: 5,
                menuTitle: '太もも周り（太ももEMS30分付き）',
                menuTime: '所要時間 :  90分目安',
                menuPrices: [{
                    text: 'ビジター価格',
                    price: '￥20,000 -'
                },{
                    text: 'リピーター価格',
                    price: '￥18,000 -'
                }],
            },
        ]
        },
        {
        id: 2,
        title: '次世代EMS（電磁パルス痩身）',
        description: '電磁波の力を利用して筋収縮を促します。脂肪細胞を減少させる効果と、筋肉量を増加させる効果があります。見た目の変化も感じていただきやすく、一定期間内に定期的に施術いただくことで更に高い効果が期待できます。',
        icon: menuEMS,
        warning: (
            <div>
                ※60分以降一律25％OFF ※紹介割を適用する場合は15分の料金をベースとして30％OFFとします。<br/>
                ※価格は全て税別価格です<br/>
                ※ビジター価格＝前回来店より1ヶ月以上経過したお客様<br/>
            </div>
        ),
        backImg: emsBack,
        modalLink: '/emsModal/',
        content: [
            {
                id: 1,
                menuTitle: '15分コース',
                menuTime: '腹筋、お尻、太もも（裏・表）、二の腕、ウエストから選べます。15分刻みでパーツ変更可',
                menuPrices: [{
                    text: 'ビジター価格',
                    price: '￥7,000 -'
                },{
                    text: 'リピーター価格',
                    price: '￥7,000 -'
                }],
            },
            {
                id: 2,
                menuTitle: '30分コース',
                menuTime: '腹筋、お尻、太もも（裏・表）、二の腕、ウエストから選べます。15分刻みでパーツ変更可',
                menuPrices: [{
                    text: 'ビジター価格',
                    price: '￥14,000 -'
                },{
                    text: 'リピーター価格',
                    price: '￥13,300 -'
                }],
            },
            {
                id: 3,
                menuTitle: '45分コース',
                menuTime: '腹筋、お尻、太もも（裏・表）、二の腕、ウエストから選べます。15分刻みでパーツ変更可',
                menuPrices: [{
                    text: 'ビジター価格',
                    price: '￥21,000 -'
                },{
                    text: 'リピーター価格',
                    price: '￥18,900 -'
                }],
            },
            {
                id: 4,
                menuTitle: '60分コース',
                menuTime: '腹筋、お尻、太もも（裏・表）、二の腕、ウエストから選べます。15分刻みでパーツ変更可',
                menuPrices: [{
                    text: 'ビジター価格',
                    price: '￥28,000 -'
                },{
                    text: 'リピーター価格',
                    price: '￥23,800 -'
                }],
            },
        ]
        }
    ],
    hairRemoval: [
        {
        id: 1,
        title: '光脱毛',
        description: '持続性があり、痛みを抑えることができる特徴があります。当サロンの光脱毛は、ワックス脱毛と併用可能なハイブリッド脱毛、発毛因子となるバルジ領域に作用します。',
        icon: laserHair,
        backImg: laserBack,
        modalLink: '/laserModal/',
        isSingleMenu: 'true',
        isSetMenu: 'true',
        warning: (
            <div>
                ※生理中のVIO、お腹周りの施術不可<br/>
                ※前日までにご自身でシェービングをお願いいたします。（背中以外）<br/>
                ※剃り残しがある場合は、剃毛代として1パーツ×￥1,000別途頂戴いたします。
                ※価格は全て税別価格です<br/>
                ※ビジター価格＝前回来店より1ヶ月以上経過したお客様
            </div>
            ),
            content: [
                {
                    id: 1,
                    menuTitle: 'LLLパーツ',
                    menuTime: (
                        <div>
                            VIO / 脚オール<br/>
                            所要時間目安 : 約60分
                        </div>
                    ),
                    menuPrice: '￥8,800 -'
                },
                {
                    id: 2,
                    menuTitle: 'LLパーツ',
                    menuTime: (
                        <div>
                            全顔 / 背中<br/>
                            所要時間目安 : 約45分
                        </div>
                    ),
                    menuPrice: '￥7,700 -'
                },
                {
                    id: 3,
                    menuTitle: 'Lパーツ',
                    menuTime: (
                        <div>
                            VI / IO / 腕オール<br/>
                            所要時間目安 : 約30分~約60分
                        </div>
                    ),
                    menuPrice: '￥6,600 -'
                },
                {
                    id: 4,
                    menuTitle: 'Mパーツ',
                    menuTime: (
                        <div>
                            膝上 / 膝下<br/>
                            所要時間目安 : 約60分
                        </div>
                    ),
                    menuPrice: '￥5,500 -'
                },
                {
                    id: 5,
                    menuTitle: 'Sパーツ',
                    menuTime: (
                        <div>
                            ヒップ / 胸 / お腹 / 腕ハーフ / 脇 / V / I / O /うなじ<br/>
                            所要時間目安 : 約15分〜約60分
                        </div>
                    ),
                    menuPrice: '￥4,400 -'
                },
                {
                    id: 6,
                    menuTitle: 'SSパーツ',
                    menuTime: (
                        <div>
                            手の指甲、足の指甲<br/>
                            所要時間目安 : 約15分
                        </div>
                    ),
                    menuPrice: '￥3,300 -'
                },
            ],
            setContent: [
                {
                id: 1,
                menuTitle: '全身セット(フェイシャル除く)',
                menuTime: (
                    <div>
                        所要時間目安 : 約120分
                    </div>
                ),
                menuPrice: '￥30,000 -'
                },
            ]
        },
        {
        id: 2,
        title: 'ワックス脱毛',
        description: '即効性があり、目視可能な毛はほぼ全部抜けるため、仕上がりがキレイになります。 一時的に毛を無くしたい時に適しています。 粘膜を含めた全ての部位、ならびに毛髪の種類(白髪・金髪など)に関係なく脱毛が可能です。',
        icon: waxHair,
        warning: (
            <div>
                ※施術には、1センチ程度以上の毛の長さが必要です。<br/>
                ※価格は全て税別価格です
            </div>
        ),
        backImg: waxBack,
        isSingleMenu: 'true',
        content: [
            {
            id: 1,
            menuTitle: 'VIO',
            menuTime: (
                <div>
                Vをデザインで残す場合は+￥500<br/>
                へそ下、足のつけ根追加で＋￥2,000<br/>
                所要時間目安 :  約60分
                </div>
            ),
            menuPrice: '￥6,500 -'
            },
            {
            id: 2,
            menuTitle: '脚オール',
            menuTime: (
                <div>
                足の付け根から指まで<br/>
                所要時間目安 : 約60分
                </div>
            ),
            menuPrice: '￥7,000 -'
            },
            {
            id: 3,
            menuTitle: '腕オール',
            menuTime: (
                <div>
                肩から指まで<br/>
                所要時間目安 :  約60分
                </div>
            ),
            menuPrice: '￥6,500 -'
            },
            {
            id: 4,
            menuTitle: 'Lパーツ',
            menuTime: (
                <div>
                    背中 / VI / IO / 顔 各一箇所<br/>
                    所要時間目安 : 約45分
                </div>
            ),
            menuPrice: '￥5,000 -'
            },
            {
            id: 5,
            menuTitle: 'Mパーツ',
            menuTime: (
                <div>
                    V、I、O 各一箇所、脇、脚ハーフ<br/>
                    所要時間目安 : 約30分〜60分
                </div>
            ),
            menuPrice: '￥4,000 -'
            },
            {
            id: 6,
            menuTitle: 'Sパーツ',
            menuTime: (
                <div>
                    うなじ、鼻毛(他メニューに鼻毛オプションで1000円)<br/>
                    所要時間目安 : 約15分
                </div>
            ),
            menuPrice: '￥3,000 -'
            },
        ]
        },
        {
            id: 3,
            title: 'ワックス + 光脱毛',
            description: 'ワックス脱毛と光脱毛の組み合わせの施術メニューです。 セット割引価格でお得になっております。',
            icon: laserAndWaxHair,
            backImg: waxLaserBack,
            isSetMenu: 'true',
            isOtherMenu: 'true',
            warning: (
                <div>
                    ※効果には個人差がございます<br/>
                    ※価格は全て税別価格です
                </div>
                ),
                setContent: [
                    {
                    id: 1,
                    menuTitle: 'VIO',
                    menuTime: '所要時間目安 : 75分',
                    menuPrice: (
                        <div style={{textAlign: 'right'}}>
                            ￥13,000 -<br/>
                            <span>(セット割￥2,300OFF)</span>
                        </div>
                    ),
                    },
                    {
                    id: 2,
                    menuTitle: '顔',
                    menuTime: (
                        <div>
                            所要時間目安 : 約75分
                        </div>
                    ),
                    menuPrice: (
                        <div style={{textAlign: 'right'}}>
                            ￥10,000 -<br/>
                            <span>(セット割￥2,700OFF)</span>
                        </div>
                    ),
                    },
                    {
                    id: 3,
                    menuTitle: '脇',
                    menuTime: (
                        <div>
                            所要時間目安 : 約45分
                        </div>
                    ),
                    menuPrice: (
                        <div style={{textAlign: 'right'}}>
                            ￥6,500 -<br/>
                            <span>(セット割￥1,900OFF)</span>
                        </div>
                    ),
                    },
                    {
                    id: 4,
                    menuTitle: 'うなじ背中',
                    menuTime: (
                        <div>
                            ※背中はワックスで荒れやすいのでシェービング、うなじはワックス。<br/>
                            所要時間目安 :  約75分
                        </div>
                    ),
                    menuPrice: (
                        <div style={{textAlign: 'right'}}>
                            ￥12,000 -<br/>
                            <span>(セット割￥3,100OFF)</span>
                        </div>
                    ),
                    },
                ],
                otherContent: [
                    {
                        id: 1,
                        menuTitle: '上記以外の組み合わせ',
                        menuTime: (
                            <div>
                                （例) 脚オールワックス+光脱毛LLLパーツ（脚オール)
                                 ＝￥15,800→￥14,220
                            </div>
                        ),
                        menuPrice: (
                            <div style={{textAlign: 'right'}}>
                                一律 10% OFF<br/>
                                <span>(紹介割適用3% OFF)</span>
                            </div>
                        ),
                    },
                ]
        },
    ],
    coupon: [
        {
            id: 1,
            title: '回数券',
            description: '全メニュー3回券は有効期限半年となります。その他の回数券は全て有効期限1年間となります 有効期限は翌年に繰り越せません。当日キャンセルをされた場合、一回分の消費となります',
            icon: tickets,
            backImg: couponBack,
            isCoupon: true,
            warning: (
                <div>
                    ※使用期限がございます。<br/>
                    ※価格は全て税別価格です
                </div>
            ),
            content: [
                {
                id: 1,
                menuTitle: '全顔 (首含む)',
                menuTime: '所要時間 :  XX分目安',
                menuPrice: '￥15,000 -'
                },
                {
                id: 2,
                menuTitle: 'フェイスライン、ほうれい線のみ',
                menuTime: '所要時間 :  XX分目安',
                menuPrice: '￥10,000 -'
                },
                {
                id: 3,
                menuTitle: 'ウエスト周り（お腹EMS30分付き）',
                menuTime: '所要時間 :  XX分目安',
                menuPrice: '￥30,000 -'
                },
                {
                id: 4,
                menuTitle: '二の腕（二の腕EMS15分付き）',
                menuTime: '所要時間 :  XX分目安',
                menuPrice: '￥10,000 -'
                },
                {
                id: 5,
                menuTitle: '太もも周り（太ももEMS30分付き）',
                menuTime: '所要時間 :  XX分目安',
                menuPrice: '￥25,000 -'
                },
            ]
            },
    ]
}