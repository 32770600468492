/** @jsx jsx */
import { jsx, Box, Container, Flex, Text, Button, Heading } from 'theme-ui';
import SectionHeading from '../components/section-heading'
import { useState } from 'react';
import { StaticImage } from "gatsby-plugin-image"
import MenuAccordion from '../components/accordion/menuAccordion';

import menuForMen from '../data/menuForMen'
import menuForWomen from '../data/menuForWomen'
import CouponAccordion from '../components/accordion/couponAccordion';
import CouponAccordionWomen from '../components/accordion/couponAccordionWomen';

const Menu = () => {
    const [plan, setPlan] = useState({
        active: 'forMen',
        menuFor: menuForMen,
      });
    
      const handlePlan = (plan) => {
        if (plan === 'forMen') {
          setPlan({
            ...plan,
            active: 'forMen',
            menuFor: menuForMen,
          });
        }
        if (plan === 'forWomen') {
          setPlan({
            ...plan,
            active: 'forWomen',
            menuFor: menuForWomen,
          });
        }
      };
    
    return (
        <Box as="section" id="menu" variant="section.menu" sx={styles.menu}>
            <Container sx={styles.menuContainer}>
                <SectionHeading title="Menu" />
                <Text>カウンセリングをさせて頂き、お客様の課題やニーズ、ご予算に合わせてオーダーメイドでオススメの施術内容をご提案いたします。施術の内容や詳細が記載されていますので参考にしてください。</Text>

                <Flex sx={styles.tabSwitcher}>
                    <Button
                        sx={{color: '#61A2DD'}}
                        onClick={() => handlePlan('forMen')}
                        className={`${plan.active === 'forMen' ? 'activeMen' : ''}`}
                    >
                        For Men
                        <Text 
                          sx={{
                            fontSize: [10, 14],
                            marginLeft: ['5px', '20px'],
                            color: '#4793D7'
                          }}>
                          男性向けメニュー
                        </Text>
                    </Button>
                    <Button
                        sx={{color: '#FF8B81'}}
                        onClick={() => handlePlan('forWomen')}
                        className={`${plan.active === 'forWomen' ? 'activeWomen' : ''}`}
                    >
                        For Wemen
                        <Text 
                          sx={{
                            fontSize: [10, 14],
                            marginLeft: ['5px', '20px'],
                            color: '#FF8B81'
                          }}>
                          女性向けメニュー
                        </Text>
                    </Button>
                </Flex>
                
              <div className={`${
                    plan.active === 'forMen' ? 'tabContentMen' : 'tabContentActive'
                }`}>
                <Box>
                    <Flex sx={{
                      mb: '24px',
                      mt: ['30px', 0],
                      }}>
                        <StaticImage src="../assets/images/slimingIcon.svg" sx={{maxWidth: ['67px', '100%']}}/>
                        <Heading as="h4">痩身メニュー</Heading>
                    </Flex>
                    {plan?.menuFor?.sliming?.map((infoItem, index) => (
                      <MenuAccordion 
                        info={infoItem}
                        theme={styles.themeRed}
                        key={index}
                      />
                    ))}
                </Box>
                
                <Box>
                    <Flex sx={{
                      mb: '24px',
                      mt: ['30px', 0],
                      }}>
                        <StaticImage src="../assets/images/hairLossIcon.svg" sx={{maxWidth: ['67px', '100%']}}/>
                        <Heading as="h4">脱毛メニュー</Heading>
                    </Flex>
                    {plan?.menuFor?.hairRemoval?.map((infoItem, index) => (
                      <MenuAccordion 
                        info={infoItem}
                        theme={styles.themeBlue}
                        key={index}
                      />
                    ))}
                </Box>

                <Box>
                    <Flex sx={{
                      mb: '24px',
                      mt: ['30px', 0],
                      }}>
                        <StaticImage src="../assets/images/ticketIcon.svg" sx={{maxWidth: ['67px', '100%']}}/>
                        <Heading as="h4">その他回数券</Heading>
                    </Flex>
                    {/* {plan?.menuFor?.coupon?.map((infoItem, index) => (
                      <MenuAccordion 
                        info={infoItem}
                        theme={styles.themeRed}
                        key={index}
                      />
                    ))} */}
                    {plan.active === 'forMen' ? (
                      <CouponAccordion theme={styles.themeRed}/>
                      ) : <CouponAccordionWomen theme={styles.themeRed} />
                    }
                </Box>
              </div>

                <StaticImage src="../assets/images/menuBack.jpg"  />
            </Container>
        </Box>
    )
}

export default Menu

const styles = {
    themeBlue: {
      fontColor: '#6C8299',
      border: '#E6F0FA',
      menu: '#F2F9FF',
      menuBorder: '#E6F0FA',
      warningBack: '#E6F0FA',
      warningButton: '#9EC6EA',
      warningBorder: '2px solid #61A2DD',
      plusIcon: '#61A2DD'
    },
    themeRed: {
      fontColor: '#FF8B81',
      border: '#FFD5D1',
      menu: '#FFECEB',
      menuBorder: '#FFD5D1',
      warningBack: '#FFF3F2',
      warningButton: '#FFAEA7',
      warningBorder: '2px solid #FF8B81',
      plusIcon: '#FF8B81',
    },
    menu: {
        h4: {
            fontSize: ['16px', '24px', '28px'],
            lineHeight: 1.28,
            color: 'heading',
            marginLeft: '14px',
            alignSelf: 'center',
            backgroundImage: `-webkit-linear-gradient(left, #416585 0%, #FF8B81 85%)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
        }
    },
    heading: {
      mb: [60, null, null, 50],
    },
    tabSwitcher: {
      backgroundColor: 'white',
      margin: ['32px auto 40px', null, null, '32px auto 50px'],
      maxWidth: 1060,
      position: 'relative',
      p: 2,
      alignItems: 'baseline',
      button: {
        background: 'white',
        width: 530,
        minHeight: '48px',
        px: ['7px', '20px', '20px', '47px'],
        paddingTop: '13px',
        fontWeight: 700,
        alignItems: 'baseline',
        justifyContent: 'start',
        borderBottom: '8px solid #E6F0FA',
        fontSize: ['12px', 18, 18, 32],
        letterSpacing: '0.03em;',
        transition: 'background .5s ease-out',
        '&.active': {
          transition: 'background .5s ease-out',
          borderBottom: `20px solid`,
          borderImage: 'linear-gradient(99.22deg, #BFE1FF -0.11%, #FFBAB4 68.8%);',
          borderImageSlice: 1,
          letterSpacing: '0.03em;',
          fontWeight: 700
        },
        '&.activeMen': {
          backgroundColor: '#E6F0FA',
          borderBottom: [`8px solid`, `20px solid`],
          borderImage: 'linear-gradient(99.22deg, #BFE1FF -0.11%, #FFBAB4 68.8%);',
          borderImageSlice: 1,
          fontSize: ['12px', 20, 20, 32],
          letterSpacing: '0.03em;',
          fontWeight: 700,
          borderRadius: 'unset',
          // display: 'inline-block',
        },
        '&.activeWomen': {
          backgroundColor: '#FFF3F2',
          borderBottom: [`8px solid`, `20px solid`],
          borderImage: 'linear-gradient(99.22deg, #BFE1FF -0.11%, #FFBAB4 68.8%);',
          borderImageSlice: 1,
          fontSize: ['12px', 20, 20, 32],
          letterSpacing: '0.03em;',
          fontWeight: 700,
          borderRadius: 'unset',
          // display: 'block',
        },
        ':focus': {
          outline: '0 none',
        },
      },
    },
    tabHeaderMen: {
      fontSize: '32px',
      color: '#61A2DD',
      letterSpacing: '0.03em;',
      fontWeight: 700
    },
    menuContainer: {
      // '.tabContentActive': {
      //   animation: `${wave} .5s cubic-bezier(.17,.67,.32,.1) .3s`,
      // },
    }
  };
  