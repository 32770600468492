/** @jsx jsx */
import { jsx, Box, Image, Text, Card, Heading } from 'theme-ui';
import { useInView } from 'react-intersection-observer'
import { keyframes } from '@emotion/react'

const wave = keyframes`
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`

const AboutCard = ({color, src, title, subTitle, description, mWidth, ...props}) => {
    const [ref, inView] = useInView({
        rootMargin: "-150px 0px",
        // triggerOnce: true,
    });

    return(
        <Card 
            ref={ref} 
            {...props} 
            sx={styles.aboutCard}
            css={{
                opacity: inView ? '1' : '0',
                animation: inView ? `${wave} cubic-bezier(.17,.67,.32,.1) .3s` : 0,
            }}
        >
            <div sx={{
                backgroundColor: color,
                flex: 1,
                paddingRight: '5px'
            }}>
                <Image
                    src={src}
                    sx={{
                        minWidth: 'unset',
                        height: '100%',
                        maxWidth: mWidth,
                        display: 'block',
                        margin: '0 auto'
                    }}
                />
            </div>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    flex: [3, 4]
                }}
            >
                <Heading as="h2" sx={{ 
                    display: 'flex',
                    alignItems: 'end',
                    gap: '4px',
                    fontWeight: 700, 
                    marginBottom: '5px',
                    fontSize: ['16px', '20px'],
                    color: '#4F6172',
                }}>
                    {title}
                    <Text sx={{
                        fontSize: '14px',
                        color: '#4F6172',
                    }}>
                        {subTitle}
                    </Text>
                </Heading>
                <Text sx={{
                    fontSize: ['14px', '16px'],
                    color: '#4F6172',
                }}>
                    {description}
                </Text>
            </Box>
        </Card>
        );
    };

export default AboutCard;

const styles = {
    aboutCard: {
        maxWidth: ['325px', '960px', '600px'],
        mx: ['auto', 0],
        display: 'flex',
        border: '3px solid',
        borderImage: 'linear-gradient(99.22deg, #50ABFE -0.11%, #FFBAB4 68.8%);',
        borderImageSlice: 1,
        marginBottom: '24px',
        minHeight: ['172px', '128px'],
        boxShadow: ['10px 10px 0px 0 #ffe8e6', '36px 12px 0px 0 #ffe8e6'],
        padding: ['0 15px 0 0', '0 15px 0 13px'],
        // '@media screen and (max-width: 1360px)': {
        //     maxWidth: '960px',
        //     mx: 'auto',
        // },
    }
}