/** @jsx jsx */
import { jsx } from 'theme-ui';
import GoogleMap from "google-map-react"
import { StaticImage } from "gatsby-plugin-image"

const Map = () => {
  return (
    <div sx={{
            width: '100%', 
            height: ['218px', '539px'],
            mb: ['40px', '64px']
         }}
    >
      <GoogleMap
        bootstrapURLKeys={{ key: `${process.env.GOOGLE_MAP_API_KEY}` }}
        // 初期表示時の中心地の座標
        defaultCenter={{ lat: 35.663386164801764, lng: 139.73528364501746 }}
        // 初期表示時のズームの度合いを設定
        defaultZoom={18}
      >
      <div
        // Markerの詳細を設定
        lat={35.663386164801764}  
        lng={139.73528364501746}
        sx={{
            width: [56, 90],
            height: [56, 116]
        }}
      >
          <StaticImage 
            src="../assets/images/marker.png" 
            sx={{
                top: '-100%',
                left: '-50%'
            }}
          />
      </div>
    </GoogleMap>
  </div>
  )
}

export default Map