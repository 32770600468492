/** @jsx jsx */
import { jsx, Box, Container, Flex, Text } from 'theme-ui';
import SectionHeading from 'components/section-heading';
import About from 'components/cards/about';
import AboutCard from 'components/cards/aboutCard'
import Campaigns from 'components/campaigns'
import { StaticImage } from "gatsby-plugin-image"

import aboutCardIcon01 from 'assets/svg/aboutCardIcon01.svg';
import aboutCardIcon02 from 'assets/svg/aboutCardIcon02.svg';
import aboutCardIcon03 from 'assets/svg/aboutCardIcon03.svg';

const data = [
  {
    id: 1,
    title: 'Concept',
    description: (
      <div>
        MarMot!はお客様の自己変革を徹底的にサポートする、伴走型のコンサルティングサービスブランドです。原石から磨き上げられたダイヤモンドをモチーフとしており、ブランド名にある2つの大文字の「M」には「More & Most」という意味が込められています。あらゆる人が「私史上、最高の私になる」ためのサポートを全力で行うこと。それが「MarMot!」がお客様に約束することです。<br/>
        <br/>これまで「ファスティング」「食事指導」「筋トレ指導」を通じた「本質的な体質改善」「内側からにじみ出る美しさ」をテーマとしてサービス展開をしてまいりましたが、さらなる美を追求する方のために、満を持してエステサロン「MarMot! Beauty」をOpenいたしました。<br/>
        <br/>「私史上、最高の私へ」<br/><br/>
        私たちと一緒に目指してみませんか？
      </div>
    )
  },
  {
    id: 2,
    title: 'Our Feature',
    description: (
      <div>
        当サロンは【痩身】と【脱毛】の施術が可能な“総合型”美容エステサロンです。元々医療用に使用されていたレベルの、国産メーカーの最新ハイスペックマシンを取り揃え、<br/><br/>
        痩身は【ハイフ】と【次世代EMS(電磁パルス痩身)】<br/>
        脱毛は【ワックス脱毛】と【光脱毛】<br/><br/>
        という2×2種類の合計4種類の施術から、お客様の課題やニーズ、ご予算に合わせてオーダーメイドでオススメの施術内容をご提案いたします。<br/><br/>
        豊富な知識と技術を持つ熟練のスタッフが対応いたしますので、安心して施術を受けていただくことが可能です。<br/><br/>
        また【新築一軒家】×【完全会員制】×【時間帯当たり1組様限定の完全予約制(※)】というコロナ対策も万全の環境で運営しておりますので、どうぞ安心してお越しくださいませ。<br/><br/>
        <small>※感染症の状況次第では今後緩和する可能性がございます。</small><br/>
      </div>
    ),
  },
];

const dataCorona = [
  {
    id: 1,
    title: '当店の新型コロナ感染症対策につきまして',
    description: (
      <div>
        ・定期的な換気の実施。<br/>
        ・来店時、お客様へ検温と手指消毒を実施。<br/>
        ・お客様お1人ごとに、タオルや施術・衛生用品の交換・消毒、ドアノブやトイレ周りなどの消毒を徹底。<br/>
        ・施術者は常にマスク着用。・施術者に対し週一回のPCR検査を実施。<br/>
        ・お客様施術後、施術者の手洗いうがいを徹底。<br/>
        ・時間あたり1組限定の完全予約制。同じ時間帯に他のお客様とバッティングいたしません。<br/>
        ※感染症の状況次第では今後緩和する可能性がございます。
      </div>
    )
  },
  {
    id: 2,
    title: 'お客様へのお願い',
    description: (
      <div>
        ・マスク着用の上ご来店ください。お顔周りの施術以外は常に着用をお願いしております。<br/>
        ・発熱や咳など、ご体調が優れない場合はご来店をお控え頂きますよう、お願い申し上げます。<br/>
        ※施術者も同様です。その場合、ご迷惑おかけ致しますが、ご時世を鑑みまして双方の安全のため、何卒ご理解賜りますようお願い申し上げます。<br/>
        ・キャンセルや日時変更のご連絡は必ず前日までにお願い申し上げます。<br/>
        当日キャンセルにつきましては、予定メニューの50%をキャンセル代としてご請求申し上げます。但し、当日の急な体調不良の場合につきましてはご請求致しませんので、ご体調が優れない場合には、ご遠慮なく、早急にご連絡くださいませ。
      </div>
    )
  },
]

const AboutUs = () => {
  return (
    <Box as="section" id="aboutus" variant="section.about" sx={{mt: ['218px', 0]}}>
      <Container>
        <SectionHeading
          sx={styles.heading}
          title="About us"
        />
        <Text sx={styles.subHead}>
          完全会員制・紹介制<br/>痩身と脱毛のエステサロン
        </Text>
        <Box sx={styles.features}>
          {data?.map((item) => (
            <About key={item.id} data={item} />
          ))}
        </Box>
        </Container>

          <Flex sx={styles.featuresCardWrap}>
            <Box sx={styles.featuresCard}>
                <AboutCard color="white" src={aboutCardIcon01} title="完全紹介制" description="まずはカウンセリングからお話をお伺いさせていただき、お客様ひとりひとりに合わせて、最適な施術内容スケジュールをご提案させていただきます。"/>
                <AboutCard color="white" src={aboutCardIcon02} title="痩身 /" subTitle="ハイフ・次世代EMS" description="最新機器を使った施術、ハイフや電磁パルスなどの施術が可能です。また、美容に関する相談も合わせて承ります。" />
                <AboutCard color="white" src={aboutCardIcon03} title="脱毛 /" subTitle="ワックス脱毛・光脱毛" description="シーンや、施術箇所、ニーズに合わせて施術内容を組み替えることが可能です。カウンセリングをしながら施術内容を決めていきます。"/>
            </Box>
            <StaticImage src="../assets/images/aboutBack.jpg"
            sx={{
              height: ['218px', '432px'], 
              ml: ['0', '78px'],
              width: '650px',
              maxWidth: ['100%', 'none'],
              }}/>
          </Flex>
        
        <Container>
        <Box sx={styles.features}>
          {dataCorona?.map((item) => (
            <About key={item.id} data={item} sx={styles.h4}/>
          ))}
        </Box>
        <Box sx={styles.campaigns}>
            <Campaigns />
        </Box>
      </Container>
    </Box>
  );
};

export default AboutUs;

const styles = {
  heading: {
    marginBottom: ['50px', '24px'],
  },
  subHead: {
    fontSize: ['22px', '44px'],
    fontWeight: ['600', '400'],
    backgroundImage: `-webkit-linear-gradient(left, #416585 0%, #FF8B81 50%)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    lineHeight: ['35px', 1.5],
    letterSpacing: 'heading',
    maxWidth: '960px',
    fontFamily: `'Noto Serif JP', serif`,
    margin: '0 auto',
  },
  features: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    maxWidth: '960px',
    margin: '0 auto',
    fontFamily: 'YuGothic, "Yu Gothic", 游ゴシック, YuGothic, 游ゴシック体, "ヒラギノ角ゴ Pro W3", メイリオ, sans-serif',
    h4: {
      fontWeight: '700',
      fontSize: '26px',
    },
  },
  featuresCardWrap: {
    justifyContent: [null, null, null, null, 'flex-end', 'flex-end', 'flex-end', 'flex-end', 'center'],
    alignItems: ['unset', 'flex-start'],
    marginTop: ['70px', '128px'], 
    marginBottom: ['0', '104px'],
    marginLeft: ['0', '0', '120px'],
    '@media screen and (max-width: 1360px)': {
      flexDirection: 'column',
    },
    featuresCard: {
      '@media screen and (max-width: 1360px)': {
        px: '30px',
      },
    }
  },
  campaigns: {
    width: '100%',
    maxWidth: ['100%', '100%', '1060px'],
    margin: ['63px auto 0', '93px auto 0'],
  }
};
