/** @jsx jsx */
import {
  jsx,
  Box,
  Image
} from 'theme-ui';
import { StaticImage } from "gatsby-plugin-image"
import heroLogo from '../assets/images/heroLogo.svg'
import heroText from '../assets/svg/heroText.svg'
import { useInView } from 'react-intersection-observer'
import { keyframes } from '@emotion/react'
import { css } from '@emotion/core'
const wave = keyframes`
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`

const scroll = keyframes`
  0% {
    transform: scale(1, 0);
    transform-origin: 0 0;
  }
  35% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }
  35.1% {
    transform: scale(1, 1);
    transform-origin: 0 100%;
  }
  70%, 100% {
    transform: scale(1, 0);
    transform-origin: 0 100%;
  }
`

const text = css`
  opacity: 0;
  transform: translateY(100px);
`

const logoAnimation = css`
  animation: .5s ${wave} .3s ease-out both;
`

const textAnimation = css`
  animation: .5s ${wave} .5s ease-out both;
`

const heroAnimation = css`
  animation: .5s ${wave} .8s ease-out both;
`

const Banner = () => {
  const [ref] = useInView({
    rootMargin: "-150px 0px",
    triggerOnce: true,
  });
  return (
    <Box as="section" variant="section.banner" sx={{position: 'relative'}}>
        <Box sx={styles.contentWrapper}>
          <Image 
            ref={ref} 
            src={heroLogo} 
            sx={{
              position: 'absolute',
              left: ['31px', '10%', '114px'],
              top: ['30px', '13%', '13%'],
              width: ['22%', '13%', '13%'],
              zIndex: 1,
              opacity: 0,
            }}
            css={
              [text, logoAnimation]
            }
          />
            <StaticImage 
              src="../assets/images/hero.jpg"
              alt="banner"
              sx={{
                display: ['none', 'block']
              }}
              css={
                [text, heroAnimation]
              }
            />
            <StaticImage 
              src="../assets/images/heroSp.jpg" 
              alt="banner"
              sx={{
                display: ['block', 'none']
              }}
              css={
                [text, heroAnimation]
              }
            />
        </Box>
        <Image 
          ref={ref} 
          src={heroText} 
          sx={{
            position: ['absolute', 'absolute', 'absolute'],
            ml: ['30px', '30px', '0'],
            mb: ['61px', '61px', 'unset'],
            right: ['20px', '8%', '10%'],
            left: ['0', 'unset'],
            bottom: ['-189px', '-10%', '53px'],
            width: ['65%', '30%', '30%'],
          }}
          css={
            [text, textAnimation]
          }
          />
        <span
          sx={styles.scrollbar}
          css={[text, heroAnimation]}
        >
          SCROLL ●
        </span>
    </Box>
  );
};

export default Banner;

const styles = {
  contentWrapper: {
    gridTemplateColumns: [null, null, null, '0.9fr 1.1fr', 'repeat(2, 1fr)'],
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    maxWidth: [507, 507, 507, 324, 450],
  },
  title: {
    fontWeight: 'bold',
    fontSize: ['30px', null, null, null, '42px', '40px', '60px'],
    lineHeight: 1.33,
    letterSpacing: '-1px',
    color: 'textSecondary',
  },
  text: {
    fontSize: ['14px', '14px', '14px', '16px', '16px', '18px'],
    lineHeight: [1.85, 1.85, 1.85, 1.85, 1.85, 2.33],
    color: 'textSecondary',
    mt: ['14px', '19px'],
  },
  button: {
    display: ['none', 'flex'],
    mt: [45, 45, 45, 25, 25],
  },
  clients: {
    display: 'flex',
    alignItems: 'center',
    mt: ['20px', '45px', '45px', '30px', '45px'],
    img: {
      maxWidth: ['80px', '100%', '100%', '100%'],
      '+ img': {
        ml: ['14px', '28px', '28px', '20px'],
      },
    },
  },
  illustration: {
    mt: ['30px', '30px', 0],
    mb: ['60px', '60px', 0],
    img: {
      maxWidth: [null, null, null, null, '90%', '90%', '100%'],
    },
  },
  scrollbar: {
    display: "inline-block", 
    position: "absolute", 
    right: ["20px", "40px"], 
    bottom: ["-172px", "0"], 
    zIndex: "2", 
    padding: "10px 10px 160px", 
    overflow: "hidden", 
    color: "#61A2DD", 
    fontSize: ["10px", "14px"], 
    lineHeight: "1", 
    letterSpacing: ".2em", 
    textTransform: "uppercase", 
    textDecoration: "none", 
    writingMode: "vertical-lr",
    '&::after': {
      content: "''", 
      position: "absolute", 
      bottom: "0", 
      left: "43%", 
      width: "4px", 
      height: "150px", 
      background: "#61A2DD",
      borderRadius: '4px',
      opacity: '.5',
      animation: `${scroll} 2.5s cubic-bezier(1, 0, 0, 1) infinite;`
    }
  },
};

