/** @jsx jsx */
import { jsx, Box, Container, Text, Heading, Link, Image, Flex} from 'theme-ui'
import checkIcon from 'assets/images/checkIcon.svg';
import { StaticImage } from "gatsby-plugin-image"

import SectionHeading from '../components/section-heading'
import banner from 'assets/images/staffBanner.jpg'

const Staff = () => {
    return (
        <Box as="section" sx={{ overflowX: 'hidden' }} variant="section.staff" id="staff">
            <Container>
                <SectionHeading title="STAFF" />
                <Image src={banner} variant="sectionBanner" />
            </Container>
                <Box sx={styles.staffProfile__01}>
                    <Box sx={styles.staffProfileDetail__01}>
                        <Text as="p" sx={{
                            mb: '8px', textAlign: ['left']
                        }}>MarMot! Beauty<br sx={{
                            display: ['block', 'block', 'none']
                        }} /> 店長</Text>
                        <Heading as="h3" sx={{
                            position: 'relative', 
                            zIndex: '1',
                            fontSize: 24, 
                            fontWeight: 700, 
                            mb: ['24px', '48px'], 
                            mr: [0, 0],
                            textAlign: ['left'],
                        }}>SACHI</Heading>
                        <Text>2011年3月、東京ビューティアート卒業。 <br/>美容師免許のほかエステ、ネイル、メイクなどの総合的美容知識を習得し各種検定取得。</Text>
                        <br/>
                        <Text>
                        銀座、白金などの美容室で勤務経験、フィリピンセブ島への短期留学を経て、<br/> 2017年より恵比寿のブラジリアンワックス脱毛専門店にて勤務開始。 
                        </Text>
                        <br/>
                        <Text>2021年4月、完全会員制 総合エステサロン「MarMot!beauty」店長就任。</Text>
                        {/* <div sx={styles.staffImage__01}> */}
                            <StaticImage src="../assets/images/staff01.jpg" sx={styles.staffImage__01}/>
                        {/* </div> */}
                    </Box>
                </Box>
                <Box sx={styles.staffProfile__02}>
                    <Box sx={styles.staffProfileDetail__02}>
                        <Text as="p">食生活指導スペシャリスト<br/>SAKI NOGUCHI</Text>
                        <Heading as="h3" sx={{fontWeight: 700, lineHeight: '34px', mb: 24}}>野口 沙貴</Heading>
                        <Text as="p" sx={{mb: 24}}>・インナービューティーコンサルタント<br/>・ファスティングマスター （一般社団法人分子整合医学美容食育協会 エキスパート<br/>・ファスティングマイスター資格保持）<br/></Text>
                        <Text as="p">
                            ファスティングサポートの挫折者は0人! 100%の成功率で「“正しい食習慣と断食”によってコンプレックスと決別し、 自信を持って“自分が主役”の人生の扉を開く」サポートをしている インナービューティーコンサルタント。 自分の人生を大きく変えたファスティングを一人でも多くの人に伝えたい、 過去の自分と同じように、コンプレックスを抱えている人をサポートしたい という想いから、ファスティングのサポートを開始。 「体調最高!」で毎日を送れる人を一人でも増やすべく猛進中。
                        </Text>
                        <div sx={styles.staffImage__02}>
                            {/* <Image 
                                src={data.staff02.childImageSharp.fluid}
                            /> */}
                            <StaticImage src="../assets/images/staff02.jpg" />
                        </div>
                    </Box>
                </Box>
                <Flex 
                    sx={{
                        width: ['100%', '83.125vw'],
                        flexDirection: 'column',
                        alignItems: 'end',
                        pr: ['21px', '0']
                    }}
                >
                    <Link 
                        href="https://marmot.biz/" 
                        target="_blank" 
                        sx={{
                            variant: 'verifButton',
                            width: 'auto',
                            display: 'flex',
                            mb: '5px',
                        }}>
                        <Image src={checkIcon} sx={{mr: ['5px']}}/>
                        ファスティングサービスへ
                    </Link>
                    <Text 
                        sx={{
                            fontSize: 12,
                            color: '#A1B0BE',
                        }}>
                        ※外部リンクへ接続します
                    </Text>
                </Flex>
        </Box>
    )
}

const styles = {
    staffProfile__01: {
        width: ['100%', '76vw'],
        height: ['auto', '393px'],
        marginLeft: 'auto',
        marginBottom: ['180px', '60px'],
        background: 'linear-gradient(99.22deg, #D9EDFF -0.11%, #FFE8E6 68.8%)',
        lineHeight: 1.5,
        position: 'relative',
    },
    staffImage__01: {
        position: 'absolute',
        top: '-26px',
        left: '-54px',
        maxWidth: '264px',
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
        '@media screen and (max-width: 768px)': {
            "top": "-127px",
            "left": '20px',
            "width": "206px",
            "height": "255px",
            overflow: 'hidden',
            "objectFit": "none",
            "objectPosition": "56% 20%"
          },
    },
    staffProfile__02: {
        width: ['100%', '83.125vw'],
        height: ['auto', '393px'],
        marginBottom: '20px',
        background: 'linear-gradient(99.22deg, #D9EDFF -0.11%, #FFE8E6 68.8%)',
        fontSize: '24px',
        lineHeight: 1.5,
        position: 'relative',
    },
    staffImage__02: {
        position: 'absolute',
        top: '-26px',
        right: '685px',
        maxWidth: '220px',
        width: '220px',
        '@media screen and (max-width: 768px)': {
            "top": "-142px",
            "right": "20px",
            "width": "206px",
            "height": "255px",
            "objectFit": "cover",
            "objectPosition": "0 10%",
            overflow: 'hidden',
          },
    },
    staffProfileDetail__01: {
        maxWidth: '732px',
        marginLeft: ['auto', '210px'],
        padding: ['40px 20px', '16px 24px'],
        marginTop: ['150px', 0],
        p: {
            fontSize: [14, 16],
            position: 'relative',
            zIndex: 1,
            width: ['33vw', 'auto'],
            marginLeft: ['auto', 0],
        },
        h3: {
            width: ['33vw', 'auto'],
            marginLeft: ['auto', 0],
        }
    },
    staffProfileDetail__02: {
        maxWidth: '689px',
        marginLeft: 'auto',
        padding: ['40px 20px', '16px 24px'],
        marginTop: ['135px', 0],
        p: {
            fontSize: [14, 16],
            position: 'relative',
            zIndex: 1
        }
    }
}

export default Staff