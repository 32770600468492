import React from 'react'
import menuHihu from 'assets/images/menuHihu.png';
import menuEMS from 'assets/images/menuEMS.png'
import laserHair from 'assets/images/laserHair.png';
import waxHair from 'assets/images/waxHair.png';
import laserAndWaxHair from 'assets/images/laserAndWaxHair.png';
import tickets from 'assets/images/tickets.png';
import hifuBack from 'assets/images/hifuBack.png'
import emsBack from 'assets/images/emsBack.png'
import laserBack from 'assets/images/laserBack.png'
import waxBack from 'assets/images/waxBack.png'
import waxLaserBack from 'assets/images/waxLaserBack.png'
import couponBack from 'assets/images/couponBack.png'

export default {
    sliming: [
        {
        id: 1,
        title: 'ハイフ / HIFU',
        description: 'たるみの改善とリフトアップ効果に加え、小顔効果を得られる施術です。ほとんど痛みを感じずに施術を行うことが可能。超音波を一点に収束させ脂肪細胞の数を物理的に減らし、効果的にサイズダウンが目指せるダウンタイムがない脂肪吸引と言われています。',
        icon: menuHihu,
        backImg: hifuBack,
        modalLink: '/hifuModal/',
        warning: (
            <div>
                ※痛みの感じ方には個人差がございます。<br />
                ※価格は全て税別価格です。<br />
                ※ビジター価格：前回来店より2ヶ月以上経過したお客様。
            </div>
        ),
        content: [
            {
                id: 1,
                menuTitle: '全顔 (首含む)',
                menuTime: '所要時間：約60分',
                menuPrices: [{
                    text: 'ビジター価格',
                    price: '￥17,000 -'
                },{
                    text: 'リピーター価格',
                    price: '￥15,000 -'
                }],
            },
            {
                id: 2,
                menuTitle: 'フェイスライン、ほうれい線のみ',
                menuTime: '所要時間：約45分',
                menuPrices: [{
                    text: 'ビジター価格',
                    price: '￥12,000 -'
                },{
                    text: 'リピーター価格',
                    price: '￥10,000 -'
                }],
            },
            {
                id: 3,
                menuTitle: 'ウエスト周り（お腹EMS30分付き）',
                menuTime: '所要時間：約90分（EMS時間含む）',
                menuPrices: [{
                    text: 'ビジター価格',
                    price: '￥32,000 -'
                },{
                    text: 'リピーター価格',
                    price: '￥30,000 -'
                }],
            },
            {
                id: 4,
                menuTitle: '二の腕（二の腕EMS15分付き）',
                menuTime: '所要時間：約45分（EMS時間含む）',
                menuPrices: [{
                    text: 'ビジター価格',
                    price: '￥12,000 -'
                },{
                    text: 'リピーター価格',
                    price: '￥10,000 -'
                }],
            },
            {
                id: 5,
                menuTitle: '太もも周り（太ももEMS30分付き）',
                menuTime: '所要時間：約90分（EMS時間含む）',
                menuPrices: [{
                    text: 'ビジター価格',
                    price: '￥27,000 -'
                },{
                    text: 'リピーター価格',
                    price: '￥25,000 -'
                }],
            },
        ]
        },
        {
        id: 2,
        title: '次世代EMS（電磁パルス痩身）',
        description: '電磁波の力を利用して筋収縮を促します。脂肪細胞を減少させる効果と、筋肉量を増加させる効果があります。見た目の変化も感じていただきやすく、一定期間内に定期的に施術いただくことで更に高い効果が期待できます。',
        icon: menuEMS,
        backImg: emsBack,
        modalLink: '/emsModal/',
        warning: (
            <div>
                ※60分以降一律25％OFF<br/>
                ※紹介割を適用する場合は15分の料金をベースとして30％OFFとします。<br/>
                ※価格は全て税別価格です<br/>
                ※ビジター価格＝前回来店より1ヶ月以上経過したお客様
            </div>
        ),
        content: [
            {
                id: 1,
                menuTitle: '15分コース',
                menuTime: '腹筋、お尻、太もも（裏・表）、二の腕、ウエストから選べます。15分刻みでパーツ変更可',
                menuPrices: [{
                    text: 'ビジター価格',
                    price: '￥7,000 -'
                },{
                    text: 'リピーター価格',
                    price: '￥7,000 -'
                }],
            },
            {
                id: 2,
                menuTitle: '30分コース',
                menuTime: '腹筋、お尻、太もも（裏・表）、二の腕、ウエストから選べます。15分刻みでパーツ変更可',
                menuPrices: [{
                    text: 'ビジター価格',
                    price: '￥14,000 -'
                },{
                    text: 'リピーター価格',
                    price: '￥13,300 -'
                }],
            },
            {
                id: 3,
                menuTitle: '45分コース',
                menuTime: '腹筋、お尻、太もも（裏・表）、二の腕、ウエストから選べます。15分刻みでパーツ変更可',
                menuPrices: [{
                    text: 'ビジター価格',
                    price: '￥21,000 -'
                },{
                    text: 'リピーター価格',
                    price: '￥18,900 -'
                }],
            },
            {
                id: 4,
                menuTitle: '60分コース',
                menuTime: '腹筋、お尻、太もも（裏・表）、二の腕、ウエストから選べます。15分刻みでパーツ変更可',
                menuPrices: [{
                    text: 'ビジター価格',
                    price: '￥28,000 -'
                },{
                    text: 'リピーター価格',
                    price: '￥23,800 -'
                }],
            },
        ]
        }
    ],
    hairRemoval: [
        {
        id: 1,
        title: '光脱毛',
        description: '持続性があり、痛みを抑えることができる特徴があります。当サロンの光脱毛は、ワックス脱毛と併用可能なハイブリッド脱毛、発毛因子となるバルジ領域に作用します。',
        icon: laserHair,
        backImg: laserBack,
        modalLink: '/laserModal/',
        isSingleMenu: 'true',
        isSetMenu: 'true',
        warning: (
            <div>
                ※機械の性質上、Iライン(睾丸・陰茎)及び甲状腺付近の照射はできません。<br/>
                ※前日までにご自身でシェービングをお願いいたします。（背中以外）<br/>
                ※剃り残しがある場合は、剃毛代として1パーツ×￥1,000別途頂戴いたします。<br/>
                ※価格は全て税別価格です<br/>
                ※ビジター価格＝前回来店より1ヶ月以上経過したお客様
            </div>
        ),
        content: [
            {
                id: 1,
                menuTitle: 'LLLパーツ',
                menuTime: (
                    <div>
                        VO / 脚オール<br/>
                        所要時間目安 :  約30分~45分
                    </div>
                ),
                menuPrice: '￥11,000 -',
            },
            {
                id: 2,
                menuTitle: 'LLパーツ',
                menuTime: (
                    <div>
                        ヒゲ全顔<br/>
                        所要時間目安 :  約30分
                    </div>
                ),
                menuPrice: '￥8,800 -',
            },
            {
                id: 3,
                menuTitle: 'Lパーツ',
                menuTime: (
                    <div>
                        背中 / 腕オール<br/>
                        所要時間目安 :  約30分
                    </div>
                ),
                menuPrice: '￥7,700 -'
            },
            {
                id: 4,
                menuTitle: 'Mパーツ',
                menuTime: (
                    <div>
                        脚ハーフ、Vのみ、Oのみ<br/>
                        所要時間目安 :  約15分
                    </div>
                ),
                menuPrice: '￥6,600 -'
            },
            {
                id: 5,
                menuTitle: 'Sパーツ',
                menuTime: (
                    <div>
                        ヒップ、胸、お腹、腕ハーフ、脇、
                        ヒゲ口周り、ヒゲフェイスライン<br/>
                        所要時間目安 :  それぞれ約20分~30分
                    </div>
                ),
                menuPrice: '￥5,500 -'
            },
            {
                id: 6,
                menuTitle: 'SSパーツ',
                menuTime: (
                    <div>
                        うなじ、手の指甲、足の指甲<br/>
                        所要時間目安 :  15分
                    </div>
                ),
                menuPrice: '￥3,300 -'
            },
        ],
        setContent: [
            {
            id: 1,
            menuTitle: '下半身セット',
            menuTime: (
                <div>
                    VO + 脚オール + ヒップ<br/>
                    所要時間目安 :  約90分
                </div>
            ),
            menuPrice: '￥27,000 -'
            },
            {
            id: 2,
            menuTitle: '上半身セット',
            menuTime: (
                <div>
                    腕オール+脇 + お腹 + 胸 + 背中<br/>
                    所要時間目安 :  約60分
                </div>
            ),
            menuPrice: '￥25,000 -'
            },
            {
            id: 3,
            menuTitle: '全身セット（フェイシャル除く）',
            menuTime: (
                <div>
                    所要時間目安 :  約120分
                </div>
            ),
            menuPrice: '￥45,000 -'
            },
        ]
        },
        {
        id: 2,
        title: 'ワックス脱毛',
        description: '即効性があり、目視可能な毛はほぼ全部抜けるため、仕上がりがキレイになります。 一時的に毛を無くしたい時に適しています。 粘膜を含めた全ての部位、ならびに毛髪の種類(白髪・金髪など)に関係なく脱毛が可能です。',
        icon: waxHair,
        isSingleMenu: 'true',
        isSetMenu: 'true',
        warning: (
            <div>
                ※施術には、1センチ程度以上の毛の長さが必要です。<br/>
                ※価格は全て税別価格です<br/>
            </div>
        ),
        backImg: waxBack,
        content: [
            {
                id: 1,
                menuTitle: 'VIO',
                menuTime: (
                    <div>
                        所要時間目安 :  90分Vをデザインで残す場合は+￥1,000<br/>
                        へそ下、足のつけ根追加で＋￥3,000
                    </div>
                ),
                menuPrice: '￥12,000 -'
            },
            {
                id: 2,
                menuTitle: '脚オール',
                menuTime: (
                    <div>
                        足の付根から指まで<br/>
                        所要時間目安 :  約105分
                    </div>
                ),
                menuPrice: '￥13,000 -'
            },
            {
                id: 3,
                menuTitle: '脚ハーフ',
                menuTime: (
                    <div>
                    膝上または膝下<br/>所要時間目安 : 60分
                    </div>
                ),
                menuPrice: '￥7,000 -'
            },
            {
                id: 4,
                menuTitle: '腕オール',
                menuTime: (
                    <div>
                    肩から指まで<br/>所要時間目安 : 60分
                    </div>
                ),
                menuPrice: '￥8,000 -'
            },
            {
                id: 5,
                menuTitle: 'Lパーツ',
                menuTime: (
                    <div>
                    腕ハーフ / 脇 / 背中 / V / I / O 各一箇所<br/>所要時間目安 : 約30分~45分
                    </div>
                ),
                menuPrice: '￥5,000 -'
            },
            {
                id: 6,
                menuTitle: 'Mパーツ',
                menuTime: (
                    <div>
                    ヒップ、胸、お腹<br/>所要時間目安 : 30分
                    </div>
                ),
                menuPrice: '￥4,000 -'
            },
            {
                id: 4,
                menuTitle: 'Sパーツ',
                menuTime: (
                    <div>
                    うなじ、鼻毛<br/>所要時間目安 : 15分
                    </div>
                ),
                menuPrice: '￥3,000 -'
            },
        ],
        setContent: [
            {
                id: 1,
                menuTitle: '下半身セット',
                menuTime: (
                    <div>
                        VIO + 脚オール + ヒップ<br/>
                        所要時間目安 :   約180分
                    </div>
                ),
                menuPrice: '￥25,000 -'
            },
            {
                id: 2,
                menuTitle: '上半身セット',
                menuTime: (
                    <div>
                        腕オール + 脇 + お腹 + 胸 + 背中<br/>
                        所要時間目安 :   約150分
                    </div>
                ),
                menuPrice: '￥20,000 -'
            },
            {
                id: 3,
                menuTitle: '全身セット（フェイシャル除く）',
                menuTime: (
                    <div>
                        所要時間目安 :   約300分
                    </div>
                ),
                menuPrice: '￥43,000 -'
            },
        ]
        },
        {
            id: 3,
            title: 'ワックス + 光脱毛',
            description: 'ワックス脱毛と光脱毛の組み合わせの施術メニューです。セット割引価格でお得になっております。',
            icon: laserAndWaxHair,
            backImg: waxLaserBack,
            isSetMenu: 'true',
            isOtherMenu: 'true',
            warning: (
                <div>
                    ※効果には個人差がございます。<br/>
                    ※価格は全て税別価格です<br/>
                </div>
                ),
            setContent: [
                {
                    id: 1,
                    menuTitle: 'VIOワックス＋VO光脱毛',
                    menuTime: '所要時間目安 : 120分',
                    menuPrice: (
                        <div style={{textAlign: 'right'}}>
                            ￥17,000 -<br/>
                            <span>(セット割￥6,000OFF)</span>
                        </div>
                    ),
                },
                {
                    id: 2,
                    menuTitle: '下半身セット',
                    menuTime: (
                        <div>
                            ワックス脱毛:VIO、脚パーツALL、ヒップ<br />
                            光脱毛:VO、脚パーツALL、ヒップ<br />
                            所要時間：210分
                        </div>
                    ),
                    menuPrice: (
                        <div style={{textAlign: 'right'}}>
                            ￥50,000 -<br/>
                            <span>(セット割￥6,000OFF)</span>
                        </div>
                    ),
                },
                {
                id: 3,
                menuTitle: '上半身セット',
                menuTime: (
                    <div>
                        腕オール / 脇 / お腹 / 胸 / 背中<br/>
                        所要時間目安 : 約150分
                    </div>
                ),
                menuPrice: (
                    <div style={{textAlign: 'right'}}>
                        ￥40,000 -<br/>
                        <span>(セット割￥17,900OFF)</span>
                    </div>
                ),
                },
                {
                id: 4,
                menuTitle: '全身セット (フェイシャル除く)',
                menuTime: '所要時間目安 : 300分',
                menuPrice: (
                    <div style={{textAlign: 'right'}}>
                        ￥75,000 -<br/>
                        <span>(セット割￥13,000OFF)</span>
                    </div>
                ),
                },
            ],
            otherContent: [
                {
                    id: 1,
                    menuTitle: '上記以外の組み合わせ',
                    menuTime: (
                        <div>
                            （例) 脚オールワックス+光脱毛LLLパーツ（脚オール)
                             ＝￥24,000→￥21,600
                        </div>
                    ),
                    menuPrice: (
                        <div style={{textAlign: 'right'}}>
                            一律 10% OFF<br/>
                            <span>(紹介割適用3% OFF)</span>
                        </div>
                    ),
                },
            ]
            },
    ],
    coupon: [
        {
            id: 1,
            title: '回数券',
            description: '全メニュー3回券は有効期限半年となります。その他の回数券は全て有効期限1年間となります 有効期限は翌年に繰り越せません。当日キャンセルをされた場合、一回分の消費となります',
            icon: tickets,
            backImg: couponBack,
            isCoupon: true,
            warning: (
                <div>
                    ※使用期限がございます。<br/>
                    ※価格は全て税別価格です
                </div>
            ),
            content: [
                {
                    id: 1,
                    menuTitle: '全顔 (首含む)',
                    menuTime: '所要時間 :  XX分目安',
                    menuPrice: '￥15,000 -'
                },
                {
                    id: 2,
                    menuTitle: 'フェイスライン、ほうれい線のみ',
                    menuTime: '所要時間 :  XX分目安',
                    menuPrice: '￥10,000 -'
                },
                {
                    id: 3,
                    menuTitle: 'ウエスト周り（お腹EMS30分付き）',
                    menuTime: '所要時間 :  XX分目安',
                    menuPrice: '￥30,000 -'
                },
                {
                    id: 4,
                    menuTitle: '二の腕（二の腕EMS15分付き）',
                    menuTime: '所要時間 :  XX分目安',
                    menuPrice: '￥10,000 -'
                },
                {
                    id: 5,
                    menuTitle: '太もも周り（太ももEMS30分付き）',
                    menuTime: '所要時間 :  XX分目安',
                    menuPrice: '￥25,000 -'
                },
            ]
            },
    ]
}