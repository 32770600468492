/** @jsx jsx */
import { jsx, Image, Heading, Text, Box, Flex } from 'theme-ui';
import {useRef, useState} from 'react'
import PlusIcon from '../plusIcon';
import { Link } from "gatsby-plugin-modal-routing"
import checkIcon from 'assets/images/checkIcon.svg';
import questionIcon from 'assets/images/questionIcon.svg';
import { NavLink } from 'components/link';
import { useInView } from 'react-intersection-observer'
import { keyframes } from '@emotion/react'
import { Divider } from '@theme-ui/components';

const wave = keyframes`
    from {
    opacity: 0;
    transform: translateY(100px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
`

const MenuAccordion = ({info, theme, ...props}) => {
    const [setActive, setActiveState] = useState("");
    const [setHight, setHightState] = useState("0px");
    const [setRotate, setRotateState] = useState("")

    const content = useRef(null);

    function toggleAccordion() {
        setActiveState(setActive === "" ? "active" : "");
        setHightState(
            setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
        );
        setRotateState(
            setActive === "active" ?  "" : "rotate(135deg)"
        )
    }

    const [ref, inView] = useInView({
        rootMargin: "-150px 0px",
        triggerOnce: true,
    });

    return (
        <div 
            ref={ref} 
            {...props} 
            sx={styles.accordion__section}
            css={{
                opacity: inView ? '1' : '0',
                animation: inView ? `${wave} cubic-bezier(.17,.67,.32,.1) .3s` : 0,
            }}
        >
            <button 
                sx={{
                    variant: 'accordion',
                    borderColor: theme.border,
                    background: `url(${info.backImg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: ['center', 'unset']
                }}
                className={`${setActive}`} 
                onClick={toggleAccordion}
            >
                <Image
                    src={info.icon}
                    sx={{
                        minWidth: 'unset',
                        height: '100%',
                        maxWidth: ['67px', '100%']
                    }}
                />
                <Box sx={styles.accordion__detail}>
                    <Heading sx={styles.accordion__title}>{info.title}</Heading>
                    <Text sx={styles.accordion__description}>
                        {info.description}
                    </Text>
                </Box>
                <PlusIcon fill={theme.plusIcon} sx={{transform: setRotate}}/>
            </button>
            <div ref={content} sx={{
                backgroundColor: 'white',
                overflow: 'hidden',
                transition: 'max-height 0.6s ease',
                border: '2px solid',
                borderColor: theme.menuBorder,
                borderTop: 'none',
                marginTop: '-2px',
            }} style={{maxHeight: `${setHight}`}}>
                <Text sx={{
                    padding: '15px 10px',
                    fontSize: ['12px', '16px'],
                    display: ['block', 'block', 'none']
                }}>
                    {info.description}
                </Text>
                <div sx={{
                    display: 'flex',
                    padding: ['15px 13px', '18px 32px'], 
                    alignItems: 'center',
                    backgroundColor: theme.warningBack,
                }}>
                    <Text 
                        sx={{
                            fontWeight:700, 
                            color: theme.fontColor, 
                            fontSize: ['12px', '14px'],
                        }}>
                        注意事項
                    </Text>
                    <Text sx={{
                        fontSize: ['12px', '14px'],
                        flex: '1', 
                        marginLeft: '30px'
                        }}>
                        {info.warning}
                    </Text>
                    <div sx={{
                        display: ['none', 'none', 'block']
                    }}>

                        
                        {info.isCoupon === true ? ('') : (
                            <button sx={{variant: 'verifButton', background: theme.warningButton, border: theme.warningBorder}}>
                                <Image src={checkIcon} sx={{mr: '5px'}}/>
                                <NavLink to="treatment" label="施術の流れを確認" />
                            </button>
                        )}

                        {info.modalLink ? (
                            <button sx={{variant: 'verifButton', mt: '15px', background: theme.warningButton, border: theme.warningBorder}}>
                                <Image src={questionIcon} sx={{mr: '2px'}}/>
                                <Link to={info.modalLink} asModal sx={{textDecoration: 'none', color: 'white'}}>機器の詳細を確認</Link>
                            </button>
                        ) : ''}
                    </div>
                </div>
                <div sx={{padding: ['15px 12px', '32px']}}>
                    <Flex sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '15px',
                        display: ['flex', 'flex', 'none']
                    }}>

                        {info.isCoupon === true ? ('') : (
                            <button sx={{
                                variant: 'verifButton', 
                                background: theme.warningButton, 
                                border: theme.warningBorder,
                                width: ['48%', null],
                                }}>
                                    <Image src={checkIcon} sx={{mr: '3px'}}/>
                                    
                                    <NavLink to="treatment" label="施術の流れを確認" />
                            </button>
                        )}
                        
                        {info.modalLink ? (
                            <button  sx={{
                                variant: 'verifButton', 
                                background: theme.warningButton, 
                                border: theme.warningBorder,
                                width: ['48%', null],
                                textAlign: 'center',
                                }} asModal>
                                    <Image src={questionIcon} sx={{mr: '9px'}}/>
                                    <Link to={info.modalLink} asModal sx={{textDecoration: 'none', color: 'white'}}>機器の詳細を確認</Link>
                            </button>
                        ) : ''}
                    </Flex>
                    {info?.isSingleMenu ? (
                        <Flex>
                            <Heading sx={styles.accordion__menuHead}>単品メニュー</Heading>
                            <Divider />
                        </Flex>
                    ) : ''}
                    <div sx={styles.accordion__menuCardWrap}>
                    {info?.content?.map((item, index) => (
                            <div sx={{
                                height: '128px',
                                padding: ['10px', '15px'],
                                backgroundColor: theme.menu,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }} key={index}>
                                <div 
                                    sx={{
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        marginRight: '43px', 
                                        flex: 1
                                    }}>
                                    <Heading
                                        sx={{
                                            fontSize: ['14px', '18px'] ,
                                            fontWeight: 700, 
                                            letterSpacing: '0.03em',
                                            borderBottom: '4px solid',
                                            borderColor: theme.menuBorder,
                                            color: '#62788D',
                                            fontFamily: 'YuGothic,"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体","ヒラギノ角ゴ Pro W3","メイリオ",sans-serif'
                                        }}>
                                        {item.menuTitle}
                                    </Heading>
                                    <Text sx={{
                                        fontSize: '12px',
                                        my: [0, '12px'],
                                        mt: ['4px', null],
                                        color: '#62788D',
                                    }}>
                                        {item.menuTime}
                                    </Text>
                                </div>
                                <div>
                                    {item.menuPrices ? (
                                        <div
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}>
                                            {item.menuPrices.map((item, index) => (
                                                <div key={index}>
                                                    <Text sx={{
                                                        fontSize: '12px',
                                                        fontFamily: '"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体"',
                                                        color: '#62788D',
                                                        fontWeight: 700,
                                                    }}>
                                                        {item.text}
                                                    </Text>
                                                    <Text sx={{
                                                        fontSize: '18px',
                                                        fontFamily: '"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体"',
                                                        color: '#62788D',
                                                    }}>
                                                        {item.price}
                                                    </Text>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <Heading sx={{
                                            fontSize: ['16px', '22px'],
                                            fontFamily: '"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体"',
                                            color: '#62788D',
                                        }}>
                                            {item.menuPrice}
                                        </Heading>
                                    )}

                                </div>
                            </div>
                            ))}
                        </div>

                    {info?.isSetMenu ? (
                        <Flex>
                            <Heading sx={styles.accordion__menuHead}>セットメニュー</Heading>
                            <Divider />
                        </Flex>
                    ) : ''}
                    {info?.isSetMenu ? (
                    <div sx={styles.accordion__menuCardWrap}>
                    {info?.setContent?.map((item, index) => (
                            <div sx={{
                                minHeight: '128px',
                                padding: ['10px', '15px'],
                                backgroundColor: theme.menu,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }} key={index}>
                                <div 
                                    sx={{
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        marginRight: '43px', 
                                        flex: 1
                                    }}>
                                    <Heading
                                        sx={{
                                            fontSize: ['14px', '18px'] ,
                                            fontWeight: 700, 
                                            letterSpacing: '0.03em',
                                            borderBottom: '4px solid',
                                            borderColor: theme.menuBorder,
                                            color: '#62788D',
                                            fontFamily: 'YuGothic,"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体","ヒラギノ角ゴ Pro W3","メイリオ",sans-serif'
                                        }}>
                                        {item.menuTitle}
                                    </Heading>
                                    <Text sx={{
                                        fontSize: '12px',
                                        my: [0, '12px'],
                                        mt: ['4px', null],
                                        color: '#62788D',
                                    }}>
                                        {item.menuTime}
                                    </Text>
                                </div>
                                <div>
                                    <Heading sx={{
                                        fontSize: ['16px', '22px'], 
                                        fontFamily: '"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体"',
                                        color: '#62788D',
                                    }}>
                                        {item.menuPrice}
                                    </Heading>
                                </div>
                            </div>
                            ))}
                        </div>
                        ) : ''}


                    {info?.isOtherMenu ? (
                    <Flex>
                        <Heading sx={styles.accordion__menuHead}>他メニュー</Heading>
                        <Divider />
                    </Flex>
                    ) : ''}
                    {info?.isOtherMenu ? (
                    <div sx={styles.accordion__menuCardWrap}>
                    {info?.otherContent?.map((item, index) => (
                            <div sx={{
                                minHeight: '128px',
                                padding: ['10px', '15px'],
                                backgroundColor: theme.menu,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }} key={index}>
                                <div 
                                    sx={{
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        marginRight: '43px', 
                                        flex: 1
                                    }}>
                                    <Heading
                                        sx={{
                                            fontSize: ['14px', '18px'] ,
                                            fontWeight: 700, 
                                            letterSpacing: '0.03em',
                                            borderBottom: '4px solid',
                                            borderColor: theme.menuBorder,
                                            color: '#62788D',
                                            fontFamily: 'YuGothic,"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体","ヒラギノ角ゴ Pro W3","メイリオ",sans-serif'
                                        }}>
                                        {item.menuTitle}
                                    </Heading>
                                    <Text sx={{
                                        fontSize: '12px',
                                        my: [0, '12px'],
                                        mt: ['4px', null],
                                        color: '#62788D',
                                    }}>
                                        {item.menuTime}
                                    </Text>
                                </div>
                                <div>
                                    <Heading sx={{
                                        fontSize: ['16px', '22px'], 
                                        fontFamily: '"Yu Gothic","游ゴシック",YuGothic,"游ゴシック体"',
                                        color: '#62788D',
                                    }}>
                                        {item.menuPrice}
                                    </Heading>
                                </div>
                            </div>
                            ))}
                        </div>
                        ) : ''}

                </div>
            </div>
        </div>       
    )
}

export default MenuAccordion

const styles = {
    accordion__section: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: ['10px', '24px'],
    },
    accordion__title: {
        fontWeight: 600,
        fontSize: ['16px', '24px'],
        color: '#4F6172',
    },
    accordion__detail: {
        paddingLeft:'8px',
    },
    rotate: {
        transform: 'rotate(90deg)',
    },
    accordion__text: {
        fontWeight: 400,
        fontSize: 14,
        padding: '10px',
    },
    accordion__description: {
        fontSize: 14,
        width: '54%',
        lineHeight: '18.2px',
        display: ['none', 'none', 'none', 'block'],
        color: '#4F6172',
    },
    accordion__menuCardWrap: {
        display: 'grid',
        gridGap: '16px',
        gridTemplateColumns: ['1fr', '1fr', '1fr', '1fr 1fr'],
        mb: ['32px'],
        span: {
            fontSize: [12, 16],
            textAlign: "center", 
            display: "block", 
            fontWeight: "bold"
        }
    },
    accordion__menuHead: {
        fontSize: ['16px', '18px'],
        lineHeight: 1.28,
        alignSelf: 'center',
        backgroundImage: `-webkit-linear-gradient(320deg, #416585 0%, #FF8B81 60%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        marginBottom: '20px',
        fontWeight: 700,
    }   
}