/** @jsx jsx */
import { jsx, Box, Heading, Text, Divider, Flex } from 'theme-ui';

const About = ({ data, ...props }) => {
  return (
    <Box sx={styles.feature} {...props}>
      <Box>
        <Flex sx={{alignItems: 'center'}}>
          <Heading as="h4" {...props}>{data?.title}</Heading>
          <Divider />
        </Flex>
        <Text as="p">{data?.description}</Text>
      </Box>
    </Box>
  );
};

export default About;

const styles = {
  feature: {
    // textAlign: ['center', 'center', 'center', 'center', 'left'],
    // maxWidth: [230, 230, 230, 230, 'none'],
    width: '100%',
    mx: 'auto',
    mt: ['40px', '80px'],
    h4: {
      fontSize: ['18px', '32px'],
      lineHeight: 1.28,
      color: 'heading',
      marginBottom: '20px',
      backgroundImage: `-webkit-linear-gradient(left, #416585 0%, #FF8B81 85%)`,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    p: {
      fontSize: [14, 16],
      lineHeight: ['200%', 1.88],
      color: 'text',
      letterSpacing: ['-0.09em', 'unset'],
      small: {
        fontSize: [14, 16],
        letterSpacing: ['-0.11em', 'unset']
      }
    },
  },
};
